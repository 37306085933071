import React from 'react';
import {Box, IconButton, Tooltip, styled, Typography} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import InfoIcon from "@mui/icons-material/Info";

const FlagButton = styled('img')(({ theme }) => ({
    width: '40px',
    height: '30px',
    cursor: 'pointer',
    border: '1px solid #ddd',
    borderRadius: '4px',
    margin: '0 4px',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
}));

const CountrySelector = ({ countries, setCountry, toolTip }) => {
    
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            p: 2,
            backgroundColor: 'lightGray',
            borderRadius: '8px'
        }}>
            {toolTip &&
                <Tooltip
                    title={<Typography>셀을 클릭하면 자세한 정보를 알아볼 수 있어요!</Typography>}
                    placement="right-end"
                    arrow
                    sx={{marginLeft : 1}}
                >
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title="모든 국가 보기">
                <IconButton onClick={() => setCountry('')} color="primary" sx={{ mr: 1 }}>
                    <PublicIcon />
                </IconButton>
            </Tooltip>
            {countries.map((c) => (
                <Tooltip key={c.code} title={c.label}>
                    <FlagButton
                        loading="lazy"
                        srcSet={`https://flagcdn.com/w80/${c.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png`}
                        alt={c.label}
                        onClick={() => setCountry(c.code)}
                    />
                </Tooltip>
            ))}


        </Box>
    );
};

export default CountrySelector;