import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";

const QuillWithImageUpload = ({ onChange,imageCount,prevContent}) => {
    const quillRef = useRef(null); // Ref to the ReactQuill component
    const [showModal, setShowModal] = useState(false);
    const authenticatedFetch = useAuthenticatedFetch();

    const imageHandler = async () => {
        try {
            setShowModal(true);

            const files = await selectImageFiles();
            const file = files[0];
            const fileSize = file.size; // 파일의 용량

            if (fileSize > 10 * 1024 * 1024) { // 10MB 이하인 경우에만 이미지 출력
                alert("이미지 용량이 10MB를 초과합니다");
                return;
            }

            if(imageCount.current >= 10){
                alert("이미지 개수가 10개를 초과합니다");
                return;
            }

            const quillObj = quillRef.current?.getEditor();
            const range = quillObj?.getSelection();

            const formData = new FormData();
            formData.append("img", file, file.name);

            const response = await uploadImages(formData);
            const fileName = await response.text();

            insertImages(quillObj, range, fileName);
            imageCount.current = countImages();
        } catch (error) {
            console.log(error);
        }
    };

    const countImages = () => {
        const quillObj = quillRef.current?.getEditor();
        const content = quillObj?.root.innerHTML;
        const tempElement = document.createElement("div");
        tempElement.innerHTML = content;
        const images = tempElement.getElementsByTagName("img");
        return images.length;
    };

    const selectImageFiles = () => {
        return new Promise((resolve, reject) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
            input.onchange = () => {
                const files = Array.from(input.files);
                resolve(files);
            };
        });
    };

    const uploadImages = (formData) => {
        return authenticatedFetch(`/api/img/add`, {
            method: "POST",
            body: formData,
        });
    };

    const insertImages = (quillObj, range, fileNameList) => {
        quillObj?.insertEmbed(
            range.index,
            "image",
            `/api/img/get.cf?fileName=${fileNameList}`
        );
        quillObj?.insertText(range.index + 1, "\n");
        quillObj?.setSelection(range.index + 2);
    };


    useEffect(() => {
        const quillObj = quillRef.current?.getEditor();

        if (prevContent) {
            quillObj.clipboard.dangerouslyPasteHTML(0, prevContent);
        }


        if (quillObj) {
            const toolbar = quillObj.getModule("toolbar");
            toolbar.addHandler("image", imageHandler);
        }
    }, [prevContent]);



    const toolbarOptions = [
        [{ header: "1" }, { header: "2" }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"],
    ];

    const modules = {
        toolbar: {
            container: toolbarOptions,
        },
        clipboard: {},
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "align",
        "link",
        "image",
    ];


    return (
        <div>
            <ReactQuill
                ref={quillRef}
                onChange={(content, delta, source, editor) => {
                    onChange(content);
                }}
                modules={modules}
                formats={formats}
            />
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>이미지는 최대 10개이며, 용량은 10MB를 넘을 수 없습니다.</p>
                        <button onClick={closeModal}>닫기</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default QuillWithImageUpload;