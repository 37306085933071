import {Box, Button, Container, Grid, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import RecentIncreaseAiddoru from "../statistic/RecentIncreaseAiddoru";
import PublicIcon from "@mui/icons-material/Public";
import {StyledImg} from "../design/StandardImg";
import {styled} from "@mui/system";

const CommunityList = () => {
    const [data, setData] = useState([]);
    const [country,setCountry] = useState('');

    useEffect(() => {
        fetch(`/api/community/list?country=${country}`)
            .then((response) => response.json())
            .then((data) => setData(data)); // 데이터 구조가 배열인 경우 바로 저장
        console.log(data);
    }, [country]);

    const StyledTable = styled('table')({
        borderCollapse: 'collapse',
        width: '100%',
        '& .MuiTableCell-root': {
            padding: '8px',
            borderRadius: '5px', // 원하는 borderRadius 값 설정
            textAlign: 'center',
        },
    });


    const freeBoard = {
        "channelThumbnailURL" : "favicon.ico",
        "communityName" : "자유 게시판",
        "communityURL" : "freeboard",
        "country" : ""
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom align="center">
                    게시판
                </Typography>

                <Grid container spacing={2}>
                    <Grid md="9"></Grid>

                    <Grid md={3} >
                        <Box sx={{marginTop:2}}>
                            <Button sx={{
                                backgroundColor: "#007BFF",color:"white",marginLeft:1}}
                                    onClick={() => setCountry("")}
                            >
                                <PublicIcon sx={{width:30,height:20}}>
                                </PublicIcon>
                            </Button>

                            <Button sx={{
                                backgroundColor: "#007BFF",marginLeft:1}}
                                    onClick={() => setCountry("KR")}
                            >
                                <img
                                    loading="lazy"
                                    width="30"
                                    height="20"
                                    srcSet={`https://flagcdn.com/w40/kr.png 2x`}
                                    alt=""
                                />
                            </Button>

                            <Button sx={{
                                backgroundColor: "#007BFF",marginLeft:1}}
                                    onClick={() => setCountry("JP")}
                            >                            <img
                                loading="lazy"
                                width="30"
                                height="20"
                                srcSet={`https://flagcdn.com/w40/jp.png 2x`}
                                alt=""
                            />
                            </Button>

                            <Button sx={{
                                backgroundColor: "#007BFF",marginLeft:1}}
                            >                            <img
                                loading="lazy"
                                width="30"
                                height="20"
                                onClick={() => setCountry("US")}
                                srcSet={`https://flagcdn.com/w40/us.png 2x`}
                                alt=""
                            />
                            </Button>
                        </Box>
                    </Grid>

                    <StyledTable sx={{ borderRadius: 4 }}>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell>#</TableCell>*/}
                                <TableCell>커뮤니티 명</TableCell>
                                <TableCell>국가</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.length > 0 &&
                                data.map((entity, index) => (
                                    <React.Fragment key={entity.id} >
                                        <TableRow
                                            sx={{ cursor: "pointer" }}
                                            className="table-row" // 호버 효과를 적용할 클래스 추가
                                            onClick={() => {
                                                window.location.href=`/community/${entity.communityURL}`;
                                            }}
                                        >
                                            <TableCell>
                                                {/*{(entity?.communityIdol) != null ? (*/}
                                                {/*    <StyledImg*/}
                                                {/*        src={`${entity?.communityIdol?.channelThumbnailURL}`}*/}
                                                {/*        style={{ width: "100px" }}*/}
                                                {/*        onClick={() => {*/}
                                                {/*            window.open("about:blank").location.href=`${entity.channelUrl}`;*/}
                                                {/*        }}*/}

                                                {/*    />*/}
                                                {/*) : (*/}
                                                {/*    <p></p>*/}
                                                {/*) }*/}

                                            </TableCell>
                                            <TableCell>
                                                <Typography>{entity.communityName === "freeBoard" ? "자유게시판" : entity.communityName}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    srcSet={`https://flagcdn.com/w40/${entity.communityIdol?.country.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                        </TableBody>
                    </StyledTable>

                </Grid>
            </Box>
        </Container>
    );
}

export default CommunityList;




