import React, {useEffect, useState} from 'react';
import {Link, redirect} from 'react-router-dom'; // useHistory import 추가
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function FindId() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        //이메일 전송 요청
        const response = await fetch('/api/auth/findId', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

    };


    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                아이디 찾기
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    label="email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                >
                    이메일로 아이디 전송하기
                </Button>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    회원정보를 잊어버리셨나요?{' '}
                    <Link to="/auth/findPw">비밀번호 찾기</Link>
                </Typography>
            </Box>
        </Container>
    );
}

export default FindId;

