import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box,
    Button, Container, Grid,
} from "@mui/material";
import RecentIncreaseCommunity from "./RecentIncreaseCommunity";
import RecentIncreaseAiddoru from "./RecentIncreaseAiddoru";
import PopularThread from "./PopularThread";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import GroupIcon from '@mui/icons-material/Group';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {Link} from "react-router-dom";
import CustomButton from "../design/CustomButton";


const StatisticButton = ({selectedButton,setSelectedButton}) => {
    const selectedBtnArr = ["PopularThread","RecentIncreaseCommunity","RecentIncreaseAiddoru"];

    const buttonStyle = {
        borderRadius: 4,
        color: "white",
        backgroundColor: '#007BFF',
        marginBottom: 4,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginRight: 2,
        // marginLeft: '-32px',
    };

    const nonSelectedButtonStyle = {
        ...buttonStyle,
        backgroundColor: "grey", // 선택된 버튼의 배경색
    };

    return (
        <Box sx={{mt:2}}>
            <CustomButton
                style={selectedButton === selectedBtnArr[0] ? buttonStyle : nonSelectedButtonStyle}
                variant="outlined"
                startIcon={<StarIcon />}
                onClick={() => setSelectedButton(selectedBtnArr[0])}
            >
                주목 게시물
            </CustomButton>

            <CustomButton
                style={selectedButton === selectedBtnArr[1] ? buttonStyle : nonSelectedButtonStyle}
                variant="outlined"
                startIcon={<GroupIcon />}
                onClick={() => setSelectedButton(selectedBtnArr[1])}
            >
                주목 커뮤니티
            </CustomButton>

            <CustomButton
                style={selectedButton === selectedBtnArr[2] ? buttonStyle : nonSelectedButtonStyle}
                variant="outlined"
                startIcon={<TrendingUpIcon />}
                onClick={() => setSelectedButton(selectedBtnArr[2])}
            >
                상승세
            </CustomButton>
            <Link to="/recommendAiddoru">
                <CustomButton
                    style={buttonStyle}
                    sx={{height:100,marginTop:2}}
                    variant="outlined"
                    startIcon={<FavoriteIcon />}
                    onClick={() => setSelectedButton(selectedBtnArr[2])}
                >
                    aiddoru 추천
                </CustomButton>
            </Link>

            <Link to="/community/freeBoard">
                <CustomButton
                    style={buttonStyle}
                    sx={{height:50,marginTop:2}}
                    variant="outlined"
                    startIcon={<ChatBubbleIcon />}
                    onClick={() => setSelectedButton(selectedBtnArr[2])}
                >
                    자유 게시판
                </CustomButton>
            </Link>


        </Box>
    )

};

export default StatisticButton;
