import React from 'react';
import {Button} from "@mui/material";

function Pagination(props) {
    const { currentPage, totalPages, onPageChange } = props;

    // 페이지 번호를 계산합니다.
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 4, totalPages);

    //최대 5개만 유지되게
    let count = 0;
    for (let i = startPage; i <= endPage; i++) {
        count++;
        pageNumbers.push(i);
        if(count == 5) break;

    }


    return (
        <div className="pagination">
            {pageNumbers.map((page) => (
                <Button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                    sx={{
                        backgroundColor: currentPage === page ? "grey" : "#212529", // 클릭된 버튼의 배경색 변경
                        marginLeft: 1,
                        marginTop: 1,
                        color: "white",
                    }}
                >
                    {page}
                </Button>

            ))}
        </div>
    );
}

export default Pagination;
