import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    MenuItem,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid/Grid';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Box from '@mui/material/Box/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { standardGrayStyle } from '../design/StandardObject';
import BlockUserModal from "../components/BlockUserModal";

function UserManagement() {
    const location = useLocation();
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const queryParams = new URLSearchParams(location.search);
    // const [viewAll, setViewAll] = useState(true);
    const [searchParam,setSearchParam] = useState("");

    // 아이돌 목록을 관리하는 상태
    const [checkedItems, setCheckedItems] = useState({});
    const [channelMap, setChannelMap] = useState({});
    const [selectedCountry, setSelectedCountry] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blockedUserId, setBlockedUserId] = useState('');


    // 체크된 유저를 업데이트하는 함수
    const handleCheckChange = (id) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [id]: !prevCheckedItems[id], // 토글 기능
        }));
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setBlockedUserId('');
    };

    const handleModalClick = () => {
        setIsModalOpen(true);
    }

    // 차단 버튼을 클릭하면 호출되는 함수
    const handleBlock = (id,blockDay) => {

        // 체크된 아이돌이 있는지 확인
        const confirmBlock = window.confirm('선택한 유저를 차단하시겠습니까?');

        if (confirmBlock) {
            // checkedItems 맵을 배열로 변환
            const data = {"id" : id,"blockDay" : blockDay};
            // const data = Object.keys(checkedItems).filter((id) => checkedItems[id]);

            fetch('/api/admin/block', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(r => console.log("차단 완") );
        // }).then(r => window.location.href = "" )

        }
    };

    useEffect(() => {

        fetch(`/api/admin/getUserList?page=${currentPage}&searchParam=${searchParam}`)
            .then((response) => response.json())
            .then((data) => {
                setUserList(data);
            });
    }, [currentPage, searchParam]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Container
            maxWidth="xl"
            sx={{
                paddingY: 4,
                textAlign: 'center',
                borderRadius: 4,
            }}
        >
            <Typography variant="h2" gutterBottom>
                유저 관리
            </Typography>


            <Grid container fullWidth spacing={1} sx={{ backgroundColor: 'grey', borderRadius: 4 }}>

                <Grid md={10}></Grid>
                <Grid md={2} sx={{padding : 3}}>
                    <TextField
                        label="유저 검색"
                        value={searchParam}
                        onChange={(e) => setSearchParam(e.target.value)}
                    />
                </Grid>

                <Grid md={12}>
                    <Paper elevation={3} sx={{ marginTop: 3, padding: 3, marginBottom: 3, backgroundColor: 'grey', borderRadius: 4 }}>
                        <Typography variant="h4" sx={{ marginBottom: 2, color: '', fontSize: '1rem' }}>
                            <FlashOnIcon fontSize="large"></FlashOnIcon>
                        </Typography>
                    </Paper>
                    {userList && userList.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <TableContainer component={Paper} fullWidth sx={standardGrayStyle}>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>썸네일이에요잉</TableCell>
                                            <TableCell>유저명</TableCell>
                                            <TableCell>차단일수</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {userList.length > 0 &&
                                            userList.map((user, index) => (
                                                <React.Fragment key={user.id}>

                                                    <TableRow sx={{ cursor: 'pointer' }}>
                                                        <TableCell align="center">
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedItems[user.id] || false} // 체크 상태를 체크된 아이템 상태에 따라 설정
                                                                onChange={() => handleCheckChange(user.id)} // 체크 상태 변경 시 핸들러 호출
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <img src={`${user.profileImgSrc}`} style={{ width: '50px', height: '50px' }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="유저명"
                                                                value={user.username}
                                                            />
                                                        </TableCell>
                                                        <Button onClick={handleModalClick}>차단</Button>
                                                        <BlockUserModal
                                                            isOpen={isModalOpen}
                                                            onClose={handleModalClose}
                                                            onConfirm={handleBlock}
                                                            userId={user.id}
                                                        />
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Box>
                    )}

                    <Button
                        onClick={() => onPageChange(currentPage - 1)}
                        sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: '#007BFF',
                            color: 'white',
                            alignContent: 'center',
                        }}
                    >
                        <NavigateBeforeIcon />
                    </Button>

                    <Button
                        sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: '#007BFF',
                            color: 'white',
                        }}
                    >
                        {currentPage}
                    </Button>

                    <Button
                        onClick={() => onPageChange(currentPage + 1)}
                        sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: '#007BFF',
                            color: 'white',
                            alignContent: 'center',
                        }}
                    >
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UserManagement;
