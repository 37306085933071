import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import UserInfoInputArea from "../components/UserInfoInputArea";

function Signup() {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const checkValidation = useState(false);
    const navigate = useNavigate();

    const handleSignup = async (e,username,password,email,selectedImg) => {
        e.preventDefault();

        setLoading(true);

        const response = await fetch('/api/auth/requestSignup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password }),
        });

        if (response?.ok) {
            setLoading(false);
            navigate('/resultPage', { state: { msg: '인증을 위해 이메일을 보냈습니다!\n조금 시간이 걸릴 수 있어요..' } });
        } else {
            response.json().then((result) => alert(result.message));
            console.error('회원가입 실패');
            setLoading(false);
        }
    };


const submitBtn = <Button
    type="submit"
    variant="outlined"
    color="primary"
    fullWidth
>
    {loading ? <CircularProgress size={24} color="inherit" /> : '회원가입'} </Button>

return (
    <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
            회원가입
        </Typography>

        <UserInfoInputArea
            {...{ submitBtn }}
            handleSubmit={handleSignup}
        />

        <Typography variant="body2" sx={{ marginTop: 2 }}>
            이미 회원이신가요?{' '}
            <Link to="/auth/login">로그인</Link>
        </Typography>

        <Typography variant="body2" sx={{ marginTop: 2 }}>
            회원정보를 잊어버리셨나요?{' '}
            <Link to="/auth/findId">아이디 찾기</Link>
        </Typography>


    </Container>
);
}

export default Signup;
