// AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext({
    isLoggedIn: false,
    role : "",
    loggedUser : "",
    accessToken : "",
    setLoggedUser: () => {},
    setIsLoggedIn: () => {},
    setRole: () => {},
    setAccessToken: () => {},
    localLogout: () => {},
});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem('isLoggedIn') === 'true'
    );
    const [role,setRole] = useState(localStorage.getItem("role"));
    const [loggedUser,setLoggedUser] = useState(localStorage.getItem("loggedUser"));
    const [accessToken,setAccessToken] = useState(localStorage.getItem("accessToken"));
    const [profileImgSrc,setProfileImgSrc] = useState(localStorage.getItem("profileImgSrc"));

    const localLogout = () => {
        setIsLoggedIn(false);
        setRole("");
        setLoggedUser("");
        setAccessToken("");
        setProfileImgSrc("");
    };

    const localLogin = (role,loggedUser,accessToken,profileImgSrc) => {
        setIsLoggedIn(true);
        setAccessToken(accessToken);
        setLoggedUser(loggedUser);
        setRole(role);
        setProfileImgSrc(profileImgSrc);
    }


    useEffect(() => {
        localStorage.setItem('isLoggedIn', isLoggedIn);
        localStorage.setItem('role', role);
        localStorage.setItem('loggedUser', loggedUser);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('profileImgSrc', profileImgSrc);
    }, [isLoggedIn,role,loggedUser,accessToken,profileImgSrc]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn,role,setRole,loggedUser ,setLoggedUser,accessToken,setAccessToken,profileImgSrc,setProfileImgSrc,localLogout,localLogin}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
