import React from 'react';
import {AppBar, Box} from "@mui/material";
import {styled} from "@mui/system";

// export const standardStyle = { marginTop:1,borderRadius:4, textAlign: 'center',backgroundColor: "grey",color:"black",padding: 3 };
export const standardStyle = { marginTop:2, marginBottom:2,borderRadius:4, textAlign: 'center',backgroundColor: "grey",color:"white",padding : 4,width:"95%" };


export const standardBlackStyle = { marginTop:2, marginBottom:2,borderRadius:4, textAlign: 'center',backgroundColor: "white",color:"white",padding : 3 };
export const standardGrayStyle = { marginTop:2, marginBottom:2,borderRadius:4, textAlign: 'center',backgroundColor: "white",color:"black",padding : 6 };

export const standardTableStyle = { backgroundColor: "white", borderRadius: 4,marginTop:2};

// function ColorStandard({ children, ...restProps })  {
//     return (
//         <Box position="static" sx={{ background: '#212529' }} {...restProps}>
//             {children}
//         </Box>
//     );
// }
// export default ColorStandard;

export const StyledTable = styled('table')({
    borderCollapse: 'collapse',
    width: '100%',
    '& .MuiTableCell-root': {
        padding: '8px',
        borderRadius: '5px', // 원하는 borderRadius 값 설정
        textAlign: 'center',
    },
});