// ChangeUserInfo.js
import React, { useEffect, useState } from 'react';
import { uxwseAuth } from '../contexts/AuthContext';
import {logout, useAuthenticatedFetch} from '../contexts/AuthenticatedFetch';
import ChangeUserInfoArea from './ChangeUserInfoArea'; // ChangeUserInfoArea 컴포넌트 추가
import { StyledImg } from '../design/StandardImg';
import UserInfoInputArea from '../components/UserInfoInputArea';
import CheckAuthenticate from "./CheckAuthenticated";

function ChangeUserInfo() {
    // const { isLoggedIn, setIsLoggedIn, role, setRole, loggedUser, setLoggedUser, accessToken, setAccessToken, localLogin } = useAuth();
    const [showChangeUserInfo, setShowChangeUserInfo] = useState(false);
    const authenticatedFetch = useAuthenticatedFetch();


    const handleModifyUserInfoSubmit = async (e, username, password, email, selectedImg) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('profileImg', selectedImg); // 이미지 파일
        formData.append('userChangeInfoRequestDto', new Blob([JSON.stringify({ username, password, email })], { type: 'application/json' }));

        const response = await authenticatedFetch('/api/auth/modifyUserInfo', {
            method: 'POST',
            body : formData,
        });

        if (response?.ok) {
            alert("유저 정보가 변경됐습니다 재로그인 해주세요")
            logout();
        } else {
            response.json().then((result) => alert(result.message));
        }
    };

    const [inputUsername,setInputUsername] = useState("");


    return (
        <>
            {showChangeUserInfo === true ? (
                <ChangeUserInfoArea
                    showChangeUserInfo={showChangeUserInfo}
                    handleModifyUserInfoSubmit={handleModifyUserInfoSubmit}
                    inputUsername={inputUsername}
                />
            ) : (
                <CheckAuthenticate setShowChangeUserInfo={setShowChangeUserInfo} setInputUsername={setInputUsername} />
            )}
        </>
    );
}

export default ChangeUserInfo;
