import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    Divider,
    TextField,
    Typography,
} from '@mui/material';

function AddCommunity() {
    const [communityName, setCommunityName] = useState('');
    const [communityURL, setCommunityURL] = useState('');
    const [idolName, setIdolName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();


        alert(communityName,communityURL,idolName);
        // 데이터를 서버로 전송
        try {
            const response = await fetch('/api/admin/community', {
                method: 'POST',
                body: JSON.stringify({ communityName, communityURL,idolName }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // 서버 응답을 처리할 수 있습니다.
            console.log('서버 응답:', response.data);

            console.log(JSON.stringify({ communityName, communityURL,idolName }));
            // 데이터를 서버로 성공적으로 전송한 후 폼 초기화
            // window.location.href= "/admin";
        } catch (error) {
            // 오류 처리
            console.error('오류:', error);
        }
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h2" gutterBottom>
                새 커뮤니티 추가
            </Typography>

            <Box>
                                <TextField
                    label="커뮤니티 이름"
                    variant="outlined"
                    fullWidth
                    value={communityName}
                    onChange={(e) => setCommunityName(e.target.value)}
                    required
                    margin="normal"
                />
                <TextField
                    label="커뮤니티 URL"
                    variant="outlined"
                    fullWidth
                    value={communityURL}
                    onChange={(e) => setCommunityURL(e.target.value)}
                    required
                    margin="normal"
                />
                <TextField
                    label="커뮤니티 IDOL 아직 미완성"
                    variant="outlined"
                    fullWidth
                    value={idolName}
                    onChange={(e) => setIdolName(e.target.value)}
                    margin="normal"
                />
                <Button type="submit" variant="outlined" color="primary" onClick={handleSubmit}>
                    제출
                </Button>
            </Box>


            <Divider style={{ margin: '16px 0' }} />
        </Container>
    );
}

export default AddCommunity;
