import React from 'react';
import {Box, Typography, Grid, Paper, TableCell} from '@mui/material';
import { styled } from '@mui/system';
import { StyledImg } from "../design/StandardImg";
import TimeValueChart from "./TimeValueChart";
import CustomButton from "../design/CustomButton";
import moment from "moment";

const InfoItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[4],
    },
}));

const ChannelInfo = ({ idol }) => {
    return (
        <Box sx={{ padding: 3, backgroundColor: "#82ca9d" , borderRadius: 2, boxShadow: 3}}>
            <Grid container spacing={3} align="center">
                <Grid item xs={12} md={12} >
                    <StyledImg
                        src={idol.channelThumbnailURL}
                        alt={`${idol.channelName} Thumbnail`}
                        onClick={() => window.open(idol.channelUrl, '_blank')}
                        sx={{ width: '100%', height: 'auto', borderRadius: 2, cursor: 'pointer' }}
                    />
                </Grid>
                <Grid item xs={12} md={4} align="center" ></Grid>


                <Grid item xs={12} md={12}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
                        <InfoItem>
                            <Box>
                                {idol.channelName}
                                <img
                                    loading="lazy"
                                    width="30"
                                    height="20"
                                    sx={{marginLeft : 6}}
                                    srcSet={`https://flagcdn.com/w40/kr.png 2x`}
                                    alt=""
                                />
                            </Box>
                        </InfoItem>
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <InfoItem>
                                <Typography variant="subtitle1">구독자수</Typography>
                                <Typography variant="h6">{idol.subscriberCount}</Typography>
                            </InfoItem>
                        </Grid>
                        <Grid item xs={4}>
                            <InfoItem>
                                <Typography variant="subtitle1">영상 수</Typography>
                                <Typography variant="h6">{idol.videoCount}</Typography>
                            </InfoItem>
                        </Grid>
                        <Grid item xs={4}>
                            <InfoItem>
                                <Typography variant="subtitle1">총 조회수</Typography>
                                <Typography variant="h6">{idol.viewCount}</Typography>
                            </InfoItem>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <InfoItem>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="subtitle1">생성일자</Typography>
                                    <Typography variant="h6">
                                        {idol.publishedAt ? moment(idol.publishedAt).format('YYYY.MM.DD') : '정보 없음'}
                                    </Typography>
                                </Box>
                                <Box sx={{mt:2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="subtitle1">전날대비 구독자 상승</Typography>
                                    <Typography variant="h6">
                                        {idol?.idolToday && idol.idolToday.subscribeDiffPercentage && idol.idolToday.subscribeDiffPercentage + "% "}
                                    </Typography>
                                </Box>
                            </InfoItem>
                        </Grid>

                        <Grid item xs={12}>
                            <InfoItem>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography variant="subtitle1">최근 영상</Typography>
                                    <Typography variant="h6">
                                        {idol.idolVideoInfoList?.length > 0
                                            &&
                                            idol.idolVideoInfoList.slice(0,3).map((idolVideoInfo) => (
                                                <StyledImg src={idolVideoInfo.videoThumbnailURL}
                                                           onClick={() => {
                                                               window.open("about:blank").location.href=`${idol.videoThumbnailURL}`;
                                                           }}

                                                           style={{ marginLeft: "0.5rem" }} />
                                            ))
                                        }
                                    </Typography>
                                </Box>

                            </InfoItem>
                        </Grid>

                        <Grid item xs={12}>
                            <InfoItem>
                                <Typography variant="subtitle1">최근 상승세</Typography>
                                <Typography variant="h6">
                                    {idol.idolSubscriberTraceList?.length > 0
                                        &&
                                        <TimeValueChart subscriberTraceList = {idol.idolSubscriberTraceList}></TimeValueChart>
                                    }
                                </Typography>

                            </InfoItem>
                        </Grid>



                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChannelInfo;