import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";

function BlockUserModal({ isOpen, onClose, onConfirm,userId }) {
    const [blockDay, setBlockDay] = useState('');

    const handleConfirm = () => {
        if (blockDay) {
            onConfirm(userId,blockDay);
            onClose();
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>사용자 차단</DialogTitle>
            <DialogContent>
                <TextField
                    label="차단일수"
                    value={blockDay}
                    onChange={(e) => setBlockDay(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary">
                    확인
                </Button>
                <Button onClick={onClose} color="primary">
                    취소
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default BlockUserModal;