import React from 'react';
import {Box, Button, Slider, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import {Stack} from "@mui/system";

// 버튼 스타일 정의
const GradientButton = styled(Button)(({ theme }) => ({
    borderRadius: '8px',
    background: 'linear-gradient(145deg, #ffffff, #f0f0f0)', // 흰색 계열의 그라디언트
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    color: '#333', // 버튼 텍스트 색상
    textTransform: 'none', // 텍스트 변형 없애기
    '&:hover': {
        background: 'linear-gradient(145deg, #f0f0f0, #ffffff)', // 호버 시 그라디언트
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2), 0 8px 24px rgba(0, 0, 0, 0.15)', // 호버 시 그림자
    },
}));

const TagButtons = ({ tagList }) => {
    return (
        <Box
            sx={{
                width: '100%',
                overflowX: 'auto', // 수평 스크롤을 활성화
                whiteSpace: 'nowrap', // 버튼들을 한 줄로 표시
                pb: 1 // 스크롤 시 아래 여백 추가
            }}
        >
            <Stack direction="row" spacing={1}>
                {tagList && tagList.length > 0 ? (
                    tagList.slice(0, Math.min(10, tagList.length)).map((tagObj, index) => (
                        <GradientButton
                            key={index}
                            variant="contained"
                            size="small"
                            onClick={() => console.log(tagObj.tag)}
                            sx={{
                                minWidth: 'auto',
                                display: 'inline-block', // 버튼들이 수평으로 나열되도록 설정
                            }}
                        >
                            {tagObj.tag}
                        </GradientButton>
                    ))
                ) : (
                    null
                )}
            </Stack>
        </Box>
    );

};

export default TagButtons;
