// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import StandardColor from '../design/StandardColor';

function Footer() {
    return (
        <Box
            sx={{
                backgroundColor: "#212529",
                color: 'white',
                padding: 2,
                textAlign: 'center',
                marginTop: 5, // 푸터를 아래로 고정
            }}
        >
            <Typography variant="body2">
                    © 2024 Aiddoru. All rights reserved.
            </Typography>
        </Box>
    );
}

export default Footer;
