import React, {useEffect, useState} from "react";
import {Box, Container, TextField} from "@mui/material";
import {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";


function UserInfoInputArea({ username, email, handleSubmit, submitBtn, selectedImg }) {
    const [changedUsername, setChangedUsername] = useState(username);
    const [changedPassword, setChangedPassword] = useState("");
    const [changedEmail, setChangedEmail] = useState(email);
    const authenticatedFetch = useAuthenticatedFetch();

    const [errors, setErrors] = useState({
        changedUsername: "",
        changedPassword: "",
        changedEmail: "",
    });


    useEffect(() => {
        setChangedUsername(username);
        setChangedEmail(email);
    }, [username]);


    const checkDuplicate = async () => {
        const newErrors = {};
        const result = await sendCheckDuplicate();
        if (result === true) {
            return true;
        } else if (result !== null) {

            if(result.code.includes("USER_INFO")){
                newErrors["changedUsername"] = result.message;
                newErrors["changedEmail"] = result.message;
            } else if (result.code.includes("USER")) {
                newErrors["changedUsername"] = result.message;
            } else {
                newErrors["changedEmail"] = result.message;
            }
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return false;
        }


        return true;
    };


    const formData = {
        changedUsername,
        changedPassword,
        changedEmail, // 변경된 값 사용
    };

    const validationRegex = [
        {
            field: "changedUsername",
            regex: /^[a-zA-Z0-9]{8,20}$/,
            message: "유저네임은 8자에서 20자까지의 영문자 및 숫자로 이루어져야 합니다.",
        },
        {
            field: "changedEmail",
            regex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
            message: "올바른 이메일 형식이어야 합니다.",
        },
        {
            field: "changedPassword",
            regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,40}$/,
            message: "비밀번호는 8자 이상 40자 이하의 영문, 숫자, 특수문자를 포함해야 합니다.",
        },
    ];



    const sendCheckDuplicate = async () => {

        const url = `/api/auth/checkDuplicateUserInfo?username=${changedUsername}&email=${changedEmail}`;
        // "/api/admin/init"로 POST 요청 보내는 로직을 여기에 추가
        const response = await authenticatedFetch(url, {
            method: "GET",
        });

        if (response.ok) {
            return true;
        }
        return await response.json();
    };



    const validationCheck = async () => {
        const newErrors = {};
        validationRegex.forEach((item) => {
            if (!item.regex.test(formData[item.field])) {
                if("changedPassword" === item.field){
                    if(changedPassword.length !== 0){
                        newErrors[item.field] = item.message;
                    }
                }else{
                    newErrors[item.field] = item.message;
                }
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return false;
        }
        return true;


    };

    const submit = async (e) => {
        if (await validationCheck() === true) {
            if (await checkDuplicate() === true) {
                handleSubmit(e,changedUsername,changedPassword,changedEmail,selectedImg);
            }
        }

    };

    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: "center" }}>
            <Box>
                <TextField
                    label="유저네임 [영문자 및 숫자 8-20]"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="text"
                    value={changedUsername} // 변경된 값 사용
                    onChange={(e) => setChangedUsername(e.target.value)}
                    error={Boolean(errors.changedUsername)}
                    helperText={errors.changedUsername}
                />
                <TextField
                    label="인증을 위한 이메일"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={changedEmail} // 변경된 값 사용
                    onChange={(e) => setChangedEmail(e.target.value)}
                    error={Boolean(errors.changedEmail)}
                    helperText={errors.changedEmail}
                />
                <TextField
                    label="패스워드 [영문, 숫자, 특수문자를 포함 8-40]"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="password"
                    value={changedPassword}
                    onChange={(e) => setChangedPassword(e.target.value)}
                    error={Boolean(errors.changedPassword)}
                    helperText={errors.changedPassword}
                />
                <Box onClick={(e) => submit(e)}>{submitBtn}</Box>
            </Box>
        </Container>
    );
}

export default UserInfoInputArea;
