import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box,
    Button, Container, Grid, IconButton, Tooltip,
} from "@mui/material";
import authenticatedFetch from "../contexts/AuthenticatedFetch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {maxHeight} from "@mui/system";
import RecentIncreaseCommunity from "./RecentIncreaseCommunity";
import AiddoruStatistic from "./AiddoruStatistic";
import RecentIncreaseAiddoru from "./RecentIncreaseAiddoru";
import StatisticButton from "./StatisticButton";
import PopularThread from "./PopularThread";
import {min} from "moment";
import GroupIcon from "@mui/icons-material/Group";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import InfoIcon from "@mui/icons-material/Info";
import PublicIcon from "@mui/icons-material/Public";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";


const StatisticArea = (headerText,statisticList,viewList,statisticClickHandler,) => {
    const [selectedButton, setSelectedButton] = useState("RecentIncreaseAiddoru");
    const selectedBtnArr = ["PopularThread","RecentIncreaseCommunity","RecentIncreaseAiddoru"];
    const [mostClickedIdolList,setMostClickedIdolList] = useState([]);


    let componentToRender;
    switch (selectedButton) {
        case selectedBtnArr[0]:
            componentToRender = <PopularThread />;
            break;
        case selectedBtnArr[1]:
            componentToRender = <RecentIncreaseCommunity />;
            break;
        default:
            componentToRender = <RecentIncreaseAiddoru />; // 선택된 버튼이 없을 때
    }

    useEffect(() => {
        fetch(`/api/idol/recentClickIdol`)
            .then((response) => {
                if (!response.ok) {
                    // response.ok가 false이면 HTTP 에러 발생, 상태 코드 확인 가능
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setMostClickedIdolList(data);
            })
            .catch((error) => {
                console.error('최근 클릭된 아이돌을 가져오는데 실패하였습니다.', error);
            });
    },[]);


    return (
        <Container
            maxWidth="xl"
            sx={{
                borderRadius: 4,
                marginTop: 2,
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >


            <Grid container spacing={0} sx={{
                flexGrow: 1, height: "100%", backgroundColor: "#F5F5F5",
                borderRadius: 4
            }}>

                <Grid container justifyContent="end" sx={{marginRight : 8}}>
                    <Tooltip
                        title={<Typography>최근 많이 클릭되고 있어요!</Typography>}
                        placement="right"
                        arrow
                        sx={{  }}
                    >
                        <IconButton
                        sx={{
                            mt:1,
                            // background: 'linear-gradient(145deg, #00bcd4, #0097a7)',
                        }}>
                            <ChatBubbleIcon sx={{ fontSize: 36, color: "linear-gradient(145deg, #00bcd4, #0097a7)" }} />
                        </IconButton>
                    </Tooltip>
                </Grid>


                <Grid
                    container
                    spacing={2}
                    sx={{
                        width: '90%',
                        margin: '0 auto', // 수평 중앙 정렬을 위한 마진 설정
                        justifyContent: 'center', // 그리드 항목들을 중앙으로 정렬
                        flexWrap: 'wrap', // 필요에 따라 자동으로 줄 바꿈
                    }}
                >
                    {mostClickedIdolList && mostClickedIdolList.slice(0, Math.min(mostClickedIdolList.length, 3)).map((idol) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={idol.id} sx={{ textAlign: 'center'}}>

                            <Button
                                sx={{
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: '8px',
                                    background: 'linear-gradient(145deg, #00bcd4, #0097a7)',
                                    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.9)', // 그림자 효과
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    textTransform: 'none', // 텍스트 변형 없애기
                                    '&:hover': {
                                        background: 'linear-gradient(145deg, #e0e0e0, #f9f9f9)', // 호버 시 그라디언트
                                        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(255, 255, 255, 0.9)', // 호버 시 그림자
                                    },
                                    color: 'white',

                                }}
                                onClick={() => window.open("about:blank").location.href = `${idol.channelUrl}`} // 클릭 이벤트 핸들러 예시
                            >

                                <img
                                    src={idol.channelThumbnailURL}
                                    alt={idol.name}
                                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                />
                                <Typography variant="h6" gutterBottom>
                                    {idol.channelName}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1, // 아이콘과 텍스트 사이의 간격을 조정합니다.
                                    }}
                                >
                                    <GroupIcon/>
                                    {idol.subscriberCount}

                                    <p></p>
                                    <TrendingUpIcon sx={{fontSize: '20px'}}/>
                                    {(idol.idolToday.subscribeDiffPercentage !== null ? idol.idolToday.subscribeDiffPercentage : 0) + "%"}
                                </Typography>


                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1, // 아이콘과 텍스트 사이의 간격을 조정합니다.
                                    }}
                                >

                                </Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>


                <Grid item md={10} sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <Box sx={{flexGrow: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {componentToRender}
                    </Box>
                </Grid>
                <Grid item md={2} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box
                        sx={{
                            color: "white",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <StatisticButton selectedButton={selectedButton} setSelectedButton={setSelectedButton}/>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default StatisticArea;
