import React, {useEffect, useState} from 'react';
import {Link, redirect, resolvePath} from 'react-router-dom'; // useHistory import 추가
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Input,
    TextField,
    Typography
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import authenticatedFetch, {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import {StyledImg} from "../design/StandardImg";
import UserInfoInputArea from "../components/UserInfoInputArea";

function CheckAuthenticate({setShowChangeUserInfo,setInputUsername}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isLoggedIn, setIsLoggedIn,role,setRole,loggedUser,setLoggedUser,accessToken,setAccessToken,localLogin} = useAuth();
    const authenticatedFetch  = useAuthenticatedFetch();


    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await authenticatedFetch('/api/auth/checkUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })

        if(!response.ok){
            response.json().then((result) => alert(result.message));
        }

        if(response?.ok){
            setInputUsername(username);
            setShowChangeUserInfo(true);
        }
    };

    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            <Box component="form">
                <Typography>유저 정보 변경을 위해 다음을 입력해주세요</Typography>

                <TextField
                    label="유저네임"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <TextField
                    label="비밀번호"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                >
                    제출
                </Button>
            </Box>

        </Container>
    );
}

export default CheckAuthenticate;
