import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    ListItemText,
    Typography,
    Box, Grid, List, ListItem,
    Menu, MenuItem,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAuthenticatedFetch } from "../contexts/AuthenticatedFetch";
import { useParams } from "react-router-dom";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

const CommentItem = ({ commentItem, onEditClick, ownerCommentId, isRecommend,isSubComment }) => {
    const authenticatedFetch = useAuthenticatedFetch();
    const { communityName, threadId } = useParams();
    const [replyCommentId, setReplyCommentId] = useState(null); // Track the parent comment for replies
    const [replyContent, setReplyContent] = useState("");
    const [deleteCommentId, setDeleteCommentId] = useState(0);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isReplying, setIsReplying] = useState(false); // Track whether the user is replying to this comment
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        setIsDeleteDialogOpen(true);
        setDeleteCommentId(commentItem.id);
        handleClose();
    };

    const handleReplyClick = (commentId) => {
        setIsReplying(true); // Set the replying state to true
        setReplyCommentId(commentId);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await authenticatedFetch(`/api/comment/${communityName}/${threadId}/${deleteCommentId}`, {
                method: "DELETE",
            });
            if (response.ok) {
                window.location.reload();
            } else {
                alert("삭제하는데 실패하였습니다");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleReplySubmit = async () => {
        try {
            const response = await authenticatedFetch(`/api/comment/${communityName}/${threadId}?ownerCommentId=${ownerCommentId}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: replyContent }),
            });

            if (response.ok) {
                window.location.reload();
            } else {
                alert("대댓글 작성에 실패하였습니다");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onLikeClick = async (isPositive, commentId) => {
        const response = await authenticatedFetch(`/api/heart/${communityName}/${commentId}/comment`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                isPositive,
                isCommentHeart: true,
            }),
        });

        if (response?.ok) {
            window.location.reload();
        } else {
            alert("추천하지 못했습니다");
        }
    };

    return (
        <Box>
            <Grid container spacing={1} alignItems="center" maxWidth="xl" sx={{backgroundColor : ""}}>
                <Grid item xs={10} container alignItems="center">
                    {/* Icon과 ListItemText를 나란히 출력 */}
                    {isSubComment ? (
                        <Grid item>
                            <SubdirectoryArrowRightIcon />
                        </Grid>
                    ) : (
                        <Grid item>
                            <Box>

                            </Box>
                        </Grid>)}
                    <Grid item>
                        <ListItemText
                            id={isRecommend ? "recommend-" + commentItem.id : commentItem.id}
                            primary={commentItem.content}
                            secondary={`작성자: ${commentItem.username}`}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    {!isRecommend && (
                        <Box sx={{  textAlign: 'right' }}>
                            <IconButton sx={{fontSize : "1.2rem"}} >
                                <ThumbUpIcon sx={{color: '#212529'}} onClick={() => onLikeClick(true, commentItem.id)} >
                                </ThumbUpIcon>
                                <Box sx={{marginLeft : 1}}>
                                    {" " + commentItem.positiveHeartCount}
                                </Box>
                            </IconButton>


                            <IconButton
                                aria-label="more"
                                aria-controls="comment-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Box>
                    )}
                </Grid>

            {/*답글입력란    */}
            {isReplying && replyCommentId === commentItem.id && (
                <>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                placeholder="대댓글 작성"
                                value={replyContent}
                                onChange={(e) => setReplyContent(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <IconButton color="#212529" onClick={handleReplySubmit}>
                                <SendIcon />
                            </IconButton>
                        </Grid>
                </>
            )}
            </Grid>

            <Menu
                id="comment-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/*<MenuItem onClick={() => onLikeClick(true, commentItem.id)}>*/}
                {/*    <ThumbUpIcon /> 좋아요*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={() => onEditClick(commentItem.id)}>
                    수정
                </MenuItem>
                <MenuItem onClick={() => handleReplyClick(commentItem.id)}>
                    답글
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                    삭제
                </MenuItem>

                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                    <DialogTitle>삭제 확인</DialogTitle>
                    <DialogContent>
                        <DialogContentText>정말로 삭제하시겠습니까?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
                            취소
                        </Button>
                        <Button onClick={handleDeleteConfirm} color="secondary">
                            삭제
                        </Button>
                    </DialogActions>
                </Dialog>
            </Menu>

        </Box>
    );
};

export default CommentItem;
