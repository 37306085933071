import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext";
import {StyledImg} from "../design/StandardImg";
import {Box, Container, Grid, Paper} from "@mui/material";

function UserProfile() {
    const {
        profileImgSrc,
        loggedUser,
    } = useAuth();

    return (

        <Container maxWidth="xl" sx={{ textAlign: "center",
            borderRadius: 4, marginTop: 2 }}>

            <Grid container spacing={1} alignItems="center" maxWidth="xl" sx={{
                marginTop : 2,
            }}>

                <Grid md={9}>
                </Grid>

                <Grid item md={3} sx={{
                    borderRadius: 4,
                }}>
                    {profileImgSrc && loggedUser != "null" &&
                        <Box>
                            <StyledImg style={{ maxWidth: 200, maxHeight: 100 }} src={`/api/img/get.cf?fileName=${profileImgSrc}`} />
                            <Box >환영합니다! {loggedUser}</Box>
                        </Box>
                    }
                </Grid>

            </Grid>
        </Container>

    );
}

export default UserProfile;
