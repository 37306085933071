import React, {useEffect, useRef, useState} from "react";
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import QuillWithImageUpload from "../editor/QuillWithImageUpload";
import {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import {StyledBtn} from "../design/StandardBtn";

const ThreadAdd = () => {
    const { communityName,threadId } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const imageCount = useRef(0);
    const [thread,setThread] = useState('');
    const authenticatedFetch = useAuthenticatedFetch();

    useEffect(() => {
        authenticatedFetch(`/api/thread/${communityName}/${threadId}`)
            .then((response) => response.json())
            .then((thread) => {
                setTitle(thread.title);
                setThread(thread);
            });
    }, [communityName, threadId,authenticatedFetch]);

    const handleModify = async () => {
        try {
            if(imageCount.current > 10){
                alert("이미지 개수가 10개를 초과합니다");
                return;
            }

            // 글 작성 요청을 보내는 코드
            const response = await authenticatedFetch(`/api/thread/${communityName}/${threadId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title, content }),
            });

            if (response.ok) {
                // 작성한 글로 이동
                navigate(`/community/${communityName}`);
            } else {
                console.error('글 작성에 실패했습니다.');
            }
        } catch (error) {
            console.error('에러 발생:', error);
        }
    };

    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            {/*<img src={`/api/img/get.cf?fileName=example.jpg`} />*/}
            <Typography variant="h4" sx={{ marginBottom: 3 }} >
                글 수정
            </Typography>
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                <input
                    type="text"
                    placeholder="제목"
                    defaultValue={thread.title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{
                        width: '100%',
                        padding: '8px',
                        fontSize: '1rem',
                        marginBottom: '1rem',
                    }}
                />


                <QuillWithImageUpload
                    onChange={(newContent) => setContent(newContent)}
                    imageCount={imageCount} // ImageCount 변수 전달
                    prevContent={thread.content}
                />

                <Button
                    sx={{ marginTop: '1rem',backgroundColor:"#007BFF",color:"white" }}
                    onClick={handleModify}
                >

                    글 수정
                </Button>
            </Paper>
        </Container>


    );
}

export default ThreadAdd;
