import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';


const questions = [
  '깔끔한 성격을 가지고 있나요?',
  '노래를 좋아하나요?',
  '운동을 좋아하나요?',
  '다른 사람의 말에 귀를 기울이는 편인가요?',
  '주변 환경에 영향을 많이 받나요?',
  '활발한가요?',
  '항상 새로운 일을 시도하려고 하나요?',
  '계획을 많이 세우나요?',
  '다른 사람의 의견에 많이 집착하나요?',
  '한 가지 것에 집착하는 편인가요?',
  '일이 잘 안 풀릴 때 쉽게 화가 나나요?',
  '게임을 좋아하나요?',
  '내향적인가요?',
  '주변 사람들과 관계가 깊나요?',
  '집돌이,집순이 인가요?',
];

const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const RecommendAiddoru = () => {
  const [ratings, setRatings] = useState(new Array(questions.length).fill(0));
  const [curPage, setCurPage] = useState(0);
  const navigate = useNavigate(); // useNavigate를 사용

  const handleRatingChange = (value, index) => {
    const newRatings = [...ratings];
    const questionIndex = curPage * 5 + index;
    newRatings[questionIndex] = value;
    setRatings(newRatings);
  };

  const handleNextQuestion = () => {
    // Check if at least one rating is selected
    if (ratings.slice(curPage * 5, curPage * 5 + 5).every((rating) => rating > 0)) {
      if (curPage < questions.length / 5 - 1) {
        setCurPage(curPage + 1);
      }
    } else {
      alert('모두 평가해주세요!'); // You can customize this message
    }
  };

  const handleSubmit = async () => {
    // Check if all ratings are selected before submitting
    if (ratings.every((rating) => rating > 0)) {
      try {
        const response = await fetch('/api/recommendaiddoru/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ratings }),
        });

        if (response.ok) {
          // 요청이 성공하면 응답을 처리
          const data = await response.json();
          navigate('/resultRecommendAiddoru', { state: { recommendedIdol: data } });
        } else {
          // 요청이 실패한 경우 오류 처리
          console.error('요청 실패');
        }
      } catch (error) {
        console.error('요청 오류:', error);
      }
    } else {
      alert('모든 평가를 선택하세요.'); // You can customize this message
    }
  };

  const questionsChunks = chunkArray(questions, 5);
  const currentQuestions = questionsChunks[curPage];

  return (
      <Container style={{ backgroundColor: '#0e191c', borderRadius: 12 }}>
        <Typography variant="h4" style={{ color: '#FFFFFF', marginBottom: '20px', textAlign: 'center' }}>
          자신과 어울리는 버튜버를 찾아보세요 총 15개의 답변이 필요합니다!
        </Typography>
        <Grid container spacing={3}>
          {currentQuestions.map((question, index) => (
              <Grid item lg={12} key={question}>
                <Paper style={{ padding: '20px' }} sx={{ backgroundColor: '' }}>
                  <Typography variant="h6" style={{ alignContent: 'center', textAlign: 'center', marginBottom: '10px' }}>
                    {question}
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {[1, 2, 3, 4, 5].map((value) => (
                        <Button
                            key={value}
                            variant={ratings[curPage * 5 + index] === value ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleRatingChange(value, index)}
                            style={{ margin: '5px' }}
                        >
                          {value}
                        </Button>
                    ))}
                  </div>
                </Paper>
              </Grid>
          ))}
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
          {curPage < questions.length / 5 - 1 && (
              <Button
                  onClick={handleNextQuestion}
                  style={{ margin: '10px',color:"white" }}
              >
                다음
              </Button>
          )}
          {curPage === questions.length / 5 - 1 && (
              <Button
                  onClick={handleSubmit}
                  style={{ margin: '10px',color:"white" }}
              >
                제출
              </Button>
          )}
        </div>
      </Container>
  );
};

export default RecommendAiddoru;
