import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box,
    Button, Container,
} from "@mui/material";
import { styled } from "@mui/system";
import GroupsIcon from "@mui/icons-material/Groups";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ExpandLess, ExpandMore } from "@mui/icons-material";


const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '30px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#0056b3',
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
    },
    '&:active': {
        backgroundColor: '#003f7f',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.4)',
    },
    '&:disabled': {
        backgroundColor: '#CCCCCC',
        color: '#666666',
        boxShadow: 'none',
    },
}));

const PopularThread = () => {
    const [statisticList, setStatisticList] = useState({ aiddoruStatisticList: [] });
    const [viewList, setViewList] = useState({ viewStatisticList: [] });
    const [isVisitOrder, setIsVisitOrder] = useState(false);

    useEffect(() => {
        fetch(`/api/statistic/popularThread?isVisitOrder=${isVisitOrder}`)
            .then((response) => response.json())
            .then((statisticList) => {
                setStatisticList(statisticList);
                if (statisticList && statisticList?.length > 0) {
                    setViewList(statisticList.slice(0, 3));
                }
            });
    }, [isVisitOrder]);

    const viewMore = () => {
        if (viewList.length === 3) {
            setViewList(statisticList.slice(0, 10));
        } else {
            setViewList(statisticList.slice(0, 3));
        }
    };

    const openThreadURL = (idolId, url, threadId) => {
        window.location.href = "/community/" + url + "/" + threadId;
    }

    return (
        <Container maxWidth="xl"
                   sx={{
                       width:"100%",
                       maxWidth:"xl",
                       borderRadius: 4,
                       marginTop: 2,
                       padding: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       alignItems: 'center',
                       backgroundColor: "#F5F5F5"
                   }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: 2,
                    backgroundColor: "white",
                    borderRadius: 4,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h6" sx={{ flex: 1, marginLeft: 8,textAlign : "center"}}>
                    최근 관심 많은 쓰레드
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FavoriteIcon
                        style={{
                            color: isVisitOrder ? 'black' : 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => setIsVisitOrder(false)}
                    />
                    <GroupsIcon
                        style={{
                            color: !isVisitOrder ? 'black' : 'grey',
                            cursor: 'pointer',
                        }}
                        onClick={() => setIsVisitOrder(true)}
                    />
                </Box>
            </Box>

            <TableContainer component={Paper} sx={{ borderRadius: 4, width: '100%', marginTop: 2 ,minHeight:"250px"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">제목</TableCell>
                            <TableCell align="center">작성자</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewList && viewList.length > 0 && viewList.map((statistic) => (
                            <TableRow
                                key={statistic.id}
                                onClick={() => openThreadURL(statistic.id, statistic.communityURL, statistic.threadId)}
                                sx={{ cursor: "pointer" }}
                            >
                                <TableCell align="center">
                                    <Box>
                                        <img
                                            src={`${statistic.communityIdolThumbnailURL}`}
                                            onClick={() => window.open("about:blank").location.href = `${statistic.channelUrl}`}
                                            style={{ width: "50px", height: "50px" }}
                                        />
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        {statistic.title}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    {statistic.username}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {statisticList.length > 3 && (
                <CustomButton onClick={viewMore} sx={{ marginTop: 2 }}>
                    {viewList.length > 3 ? <ExpandLess /> : <ExpandMore />}
                </CustomButton>
            )}
        </Container>
    );
};

export default PopularThread;
