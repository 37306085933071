import React, { useEffect, useState } from "react";
import {
    Container,
    List,
    ListItem,
    ListItemText,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Box,
    IconButton,
    Divider,
    TextField,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import Comment from "./Comment";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import Community from "./Community";

const Thread = () => {
    const { communityName, threadId } = useParams();
    const navigate = useNavigate();
    const [thread, setThread] = useState({});
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [dislikeCount, setDislikeCount] = useState(0);
    const authenticatedFetch = useAuthenticatedFetch();

    const [loggedUser,setLoggedUser] = useState(localStorage.getItem("loggedUser"));



    useEffect(() => {
        fetch(`/api/thread/${communityName}/${threadId}`)
            .then((response) => response.json())
            .then((data) => {
                setThread(data);
            })

    }, [communityName, threadId]);

    useEffect(() => {
        countHeart();
    }, [thread]); // Run countHeart when thread is updated


    const countHeart = () =>{
        if(thread?.heartList) {
                const positiveHeart = thread.heartList.filter(item => item.positive === true);
                setLikeCount(positiveHeart.length);
                setDislikeCount(thread.heartList.length - positiveHeart.length);
            // }
        }
    }


    const handleLikeClick = async (isPositive) => {
        const response = await authenticatedFetch(`/api/heart/${communityName}/${threadId}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({ isPositive}),
        });

        if (response?.ok) {
            window.location.reload();
        } else {
        }
    };

    const handleDeleteClick = async () => {
        try {
            const response = await authenticatedFetch(`/api/thread/${communityName}/${threadId}`, {
                method: "DELETE",
            });
            console.log(response);
            if (response.ok) {
                navigate(`/community/${communityName}`);
            } else {
                alert("삭제하는데 실패하였습니다 중복된 추천일 수 있습니다");
            }
        } catch (error) {
            console.log(error);
        }

    };

    const modules = {
        toolbar: false, // You can configure the toolbar if needed
    };

    return (
        <Container maxWidth="lg" sx={{ paddingY:3, textAlign: "center",borderRadius : 4,backgroundColor: "grey"}}>
            <Paper elevation={1} sx={{ padding: 3, marginBottom: 3,borderRadius : 4 }}>
                <Typography variant="h4" sx={{ marginBottom: 2 }}>
                    {thread.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ marginBottom: 1 }}>
                    작성자: {thread.username}
                </Typography>

                <ReactQuill value={thread.content} readOnly={true} modules={modules} />


                {thread.username === loggedUser && (
                    <Box justifyContent="center" sx={{ marginBottom: 2,marginTop: 2, borderRadius : 4 }}>
                        <Button
                            component={Link}
                            to={`./modify`}
                            sx={{ marginX: 1,backgroundColor : "#212529",color:"white" }}
                        >
                            수정
                        </Button>
                        <Button onClick={() => setIsDeleteDialogOpen(true)}
                                sx={{ marginX: 1,backgroundColor : "#212529",color:"white" }}
                        >
                            삭제
                        </Button>
                    </Box>
                )}


                <Box display="flex" justifyContent="center" >
                    <IconButton color="secondary" variant="outlined" onClick={() => handleLikeClick(true)}
                                sx={{mt :2 , marginX: 1, backgroundColor: "#212529", color: "white", borderRadius: 1 }}>
                        <FavoriteIcon></FavoriteIcon>
                        <Box sx={{marginLeft : 1,fontSize: "1rem"}}>
                            {likeCount}
                        </Box>
                    </IconButton>
                    <IconButton color="secondary"  onClick={() => handleLikeClick(false)}
                                sx={{mt:2, marginX: 1, backgroundColor: "#212529", color: "white", borderRadius: 1 }}>
                    <HeartBrokenIcon />
                        <Box sx={{marginLeft : 1,fontSize: "1rem"}}>
                            {dislikeCount}
                        </Box>
                    </IconButton>
                </Box>

            </Paper>


            <Box sx={{ paddingY:3,paddingX:1, textAlign: "center",borderRadius : 4,backgroundColor: "white"}}>
                <Comment commentList={thread.commentDtoList}/>
            </Box>

            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)} sx={{ marginX: 1,backgroundColor : "white",color:"#212529" }}>
                <DialogTitle>삭제 확인</DialogTitle>
                <DialogContent>
                    <DialogContentText>정말로 삭제하시겠습니까?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}  sx={{ marginX: 1,backgroundColor : "white" }}>취소</Button>
                    <Button onClick={handleDeleteClick} color="secondary"  sx={{ marginX: 1,backgroundColor : "white" }}>
                        삭제
                    </Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" justifyContent="center" sx={{ marginTop: 2 }}>
                <Button component={Link} to={`/community/${communityName}`}   sx={{color : "white",backgroundColor : "#212529"}} >돌아가기</Button>
            </Box>

            {/*    아래부분에 thread list 출력하기  */}
            <Community></Community>


        </Container>
    );
}

export default Thread;
