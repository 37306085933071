import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Button, Container, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import {useAuth} from "../contexts/AuthContext";
import Logout from "../auth/Logout";
import RankPage from "../rank/RankPage";
import {Grid3x3} from "@mui/icons-material";
import Grid from "@mui/material/Grid/Grid";
import Community from "../community/Community";
import InfoIcon from "@mui/icons-material/Info";
import PublicIcon from "@mui/icons-material/Public";
import DisplayAds from "./DisplayAds";
import RecommendIdolByTag from "../statistic/RecommendIdolByTag";


function Main() {
    const [communityList, setCommunityList] = useState({communityList: []});
    const {
        isLoggedIn,
        setIsLoggedIn,
        role,
        setRole,
        loggedUser,
        setLoggedUser,
        accessToken,
        setAccessTokens
    } = useAuth();

    const [loadingEnd,setLoadingEnd] = useState(false);




    return (
        <>
            <RecommendIdolByTag>
            </RecommendIdolByTag>

            <Grid container >
                <Container maxWidth="xl"
                           sx={{
                               textAlign: "center",
                               borderRadius: 4,
                               marginTop: 2,
                               padding: '8px'
                           }} >
                    <RankPage></RankPage>
                    <Community />
                </Container>
            </Grid>
        </>
);
}



export default Main;
