import {NavigateNext} from "@mui/icons-material";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import React, { useEffect, useState } from "react";
import {
    Container,
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Icon, Button, Tooltip, IconButton
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import RankList from "./RankList";
import RecentIncreaseAiddoru from "../statistic/RecentIncreaseAiddoru";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import FlashOnIcon from "@mui/icons-material/FlashOn"; // 번개 모양 아이콘 import
import PublicIcon from '@mui/icons-material/Public';

import {StyledImg} from "../design/StandardImg";
import InfoIcon from "@mui/icons-material/Info";
import CountrySelector from "../components/CountrySelector";
import StatisticButton from "../statistic/StatisticButton";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DisplayAds from "../components/DisplayAds";


const  RankPage = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [currentPage,setCurrentPage] = useState(1);
    const queryParams = new URLSearchParams(location.search);

    const [country,setCountry] = useState("KR");
    const [idolList, setIdolList] = useState({ idolList: []});

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const countries = [
        { code: 'KR', label: 'South Korea' },
        { code: 'US', label: 'United States' },
        { code: 'JP', label: 'Japan' },
    ];

    useEffect(() => {
        try {
            fetch(`/api/idol/rank?page=${currentPage}&country=${country}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then((response) => {
                    console.log(response)

                    var result = response.json();
                    console.log(result)
                    return result;
                })
                .then((data) => {
                    console.log("oo?")

                    setIdolList(data);
                    console.log("oo" + data)
                });

            window.scrollTo({top: 0, behavior: "auto"});
        }catch (error) {
                console.error("cannot fetch from /api/idol/rank", error);
        }

    }, [currentPage,country]); // 빈 배열을 전달하여 최초 한 번만 실행되도록 설정


    return (
        <>

            {
                idolList.length > 0 &&
                <DisplayAds />
            }
            <Container maxWidth="xl"
                       sx={{
                           backgroundColor: "#F5F5F5",
                           textAlign: "center",
                           borderRadius: 4,
                           marginTop: 2,
                           padding: '8px',
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                       }}>




                <Grid container justifyContent="center" alignItems="center"
                sx={{
                    borderRadius : 4,
                    padding: '16px', // Add padding
                }}
                >

                    <Grid md={12}/>


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' }, // 화면 크기에 따라 flexDirection 조정
                            alignItems: 'center',
                            justifyContent: { xs: 'center', sm: 'space-between' }, // 작은 화면에서는 중앙 정렬, 큰 화면에서는 공간 분배
                            width: '100%',
                            padding: { xs: 1, sm: 2 }, // 화면 크기에 따라 패딩 조정
                            borderRadius: 4,
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            backgroundColor: 'white',
                            gap: { xs: 1, sm: 2 }, // 요소들 사이의 간격 조정
                        }}
                    >
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '1rem',
                                }}
                            >
                                <AutoGraphIcon fontSize="large" />

                            </Typography>

                        </Box>

                        <CountrySelector countries={countries} setCountry={setCountry} toolTip={true} />
                    </Box>



                    <Grid md={12} >
                        {/*<Paper elevation={3} sx={{ marginTop: 3, padding: 2, marginBottom: 3,backgroundColor: "white", borderRadius: 4}}>*/}

                        {/*</Paper>*/}
                        {idolList && idolList.length > 0 &&
                            <Box sx={{ mt: 2 }}>
                                <RankList idolList={idolList} />
                            </Box>
                        }
                        <Button
                            onClick={() => onPageChange(currentPage-1)}
                            sx={{
                                marginLeft: 1,
                                marginTop: 1,
                                backgroundColor: "#212529",
                                color : "white",
                                alignContent : "center"

                            }}
                        >
                            <NavigateBeforeIcon />
                        </Button>

                        <Button sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: "#212529",
                            color : "white"
                        }}>
                            {currentPage}
                        </Button>

                        <Button
                            onClick={() => onPageChange(currentPage+1)}
                            sx={{
                                marginLeft: 1,
                                marginTop: 1,
                                backgroundColor: "#212529",
                                color : "white",
                                alignContent : "center"
                            }}
                        >
                            <NavigateNextIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Container>

        </>

    );
};

export default RankPage;
