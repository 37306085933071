// ChangeUserInfoArea.js
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Input,
    Typography,
} from '@mui/material';
import UserInfoInputArea from "../components/UserInfoInputArea";
import {StyledImg} from "../design/StandardImg";
import authenticatedFetch, {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import {textAlign} from "@mui/system";

const ChangeUserInfoArea = ({
                                showChangeUserInfo,
                                handleModifyUserInfoSubmit,
                                inputUsername,
                            }) => {
    const authenticatedFetch = useAuthenticatedFetch();
    const [username,setUsername] = useState("");
    const [email,setEmail] = useState("");
    const [profileImgSrc,setProfileImgSrc] = useState("");
    const [selectedImg,setSelectedImg] = useState(null);

    const handleFileChange = (event) => {
        setSelectedImg(event.target.files[0]);
    };

    useEffect(() => {
        if(username === ""){
            const response = authenticatedFetch(`/api/auth/getUserResponseDto?username=${inputUsername}`, {
                method: "GET",
            }).then((response) => response.json())
                .then((userInfo) => {
                    setUsername(userInfo?.username);
                    setEmail(userInfo?.email);
                    setProfileImgSrc(userInfo?.profileImgSrc);
                });
        }
    }, [username]);


    const submitBtn = <Button type="submit">제출</Button>

    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            <Typography variant="h5" component="div">
                마이페이지
            </Typography>

            {showChangeUserInfo === true && (
                <Box sx={{marginTop:10}}>
                    <Card>
                        <CardContent sx={{
                        }}>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>프로필 이미지 수정 </Typography>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="fileInput">
                                        <Input
                                            type="text"
                                            value={selectedImg ? selectedImg.name : ''}
                                            readOnly
                                        />
                                        <Button variant="contained" component="span">
                                            파일 선택
                                        </Button>
                                    </label>
                                </Grid>

                                <Grid item xs={12}>
                                    {selectedImg ? (
                                        <img
                                            src={URL.createObjectURL(selectedImg)}
                                            alt="프로필 이미지 미리보기"
                                            style={{ maxWidth: 500, maxHeight: 200 }}
                                        />
                                    ) : (

                                        <StyledImg  style={{ maxWidth: 500, maxHeight: 200 }} src={`/api/img/get.cf?fileName=${profileImgSrc}`}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardContent sx={{
                            alignItems: 'center'
                        }}>
                            <Typography> 유저 정보 변경</Typography>
                            <UserInfoInputArea username={username} email={email} handleSubmit={handleModifyUserInfoSubmit} submitBtn={submitBtn}  selectedImg={selectedImg} > </UserInfoInputArea>
                            {/*<Button onClick={() => {handleModifyUserInfoSubmit(username,email,password,img)}} >수정하기</Button>*/}
                        </CardContent>
                    </Card>


                </Box>
            )}
                </Container>
                );
            };

export default ChangeUserInfoArea;
