import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useAuth} from "../contexts/AuthContext";
import {StyledImg} from "../design/StandardImg";
import {
    Box,
    Button,
    Container, Divider,
    Grid, List, ListItem, ListItemText,
    Paper,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import {StyledTable} from "../design/StandardObject";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

function MyInfo() {
    const {
        profileImgSrc,
        loggedUser,
    } = useAuth();


    const [data, setData] = useState([]);
    const authenticatedFetch  = useAuthenticatedFetch();
    useEffect(async () => {

        const url = "/api/user/myWriteThread";
        // "/api/admin/init"로 POST 요청 보내는 로직을 여기에 추가
        const response = await authenticatedFetch(url, {
            method: "GET",
        });
        setData(response.json())
        console.log(data)
    }, []);

    return (
        <Container maxWidth="xl">
            <Button>
                <Link to="/auth/changeUserInfo">유저 정보 수정</Link>
            </Button>

            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom align="center">
                    유저 작성글
                </Typography>

                <List sx={{ backgroundColor:"white",borderRadius:4,padding:2,color:"black" }}>
                    {data?.threadList && data?.threadList?.length !== 0 ? (
                        data.threadList.map((thread, index) => (
                            <React.Fragment key={index}>
                                <ListItem disablePadding>
                                    {thread.recommended && <FlashOnIcon fontSize="large" />}
                                    <ListItemText
                                        primary={
                                            <Link
                                                to={`/community/${thread.communityName}/${thread.id}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <Typography variant="h6">{thread.title}</Typography>
                                            </Link>
                                        }
                                        secondary={`${thread.username}`}
                                        to={""}
                                    />
                                    <ListItemText sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <FavoriteIcon fontSize="small" sx={{ marginRight: '4px' }} />
                                        <Typography variant="h7" sx={{ marginRight: '8px' }}>{thread.likeCount}</Typography>
                                        <GroupsIcon fontSize="small" sx={{ marginRight: '4px' }} />
                                        <Typography variant="h7" sx={{ marginRight: '8px' }}>{thread.visitCount}</Typography>
                                        <CalendarTodayIcon fontSize="small" sx={{ marginLeft: '4px' }} />

                                    </ListItemText>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))
                    ) : (
                        data?.threadList?.length === 0 ? (
                            <Typography variant="h7" sx={{ marginRight: '8px' ,
                                textAlign: 'center',
                            }}>게시물이 없습니다</Typography>
                        ) : (
                            <Typography variant="h7" sx={{ marginRight: '8px',
                                textAlign: 'center',
                            }}>개설되지 않은 커뮤니티입니다</Typography>
                        )
                    )}

                </List>
            </Box>
        </Container>
    );
}

export default MyInfo;
