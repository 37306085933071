import React, {useEffect, useState} from 'react';
import {Link, redirect} from 'react-router-dom'; // useHistory import 추가
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isLoggedIn, setIsLoggedIn,role,setRole,loggedUser,setLoggedUser,accessToken,setAccessToken,profileImgSrc,localLogin} = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        // 실제 로그인 요청을 보내는 코드
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
            const {role,accessToken,profileImgSrc} = await response.json();
            localLogin(role,username,accessToken,profileImgSrc);
            window.location.href = "/";
        } else {
            alert("로그인에 실패하였습니다\n아이디와 비밀번호를 확인해주세요");
            // response.json().then((result) => alert(result.message));
            console.error('로그인 실패');
        }
    };


    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                로그인
            </Typography>
            <Box component="form" onSubmit={handleLogin}>
                <TextField
                    label="유저네임"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="비밀번호"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                >
                    로그인
                </Button>
            </Box>
            <Typography variant="body2" sx={{ marginTop: 2 }}>
                아직 회원이 아니신가요?{' '}
                <Link to="/auth/signup">회원가입</Link>
            </Typography>

            <Typography variant="body2" sx={{ marginTop: 2 }}>
                회원정보를 잊어버리셨나요?{' '}
                <Link to="/auth/findId">아이디 찾기</Link>
            </Typography>
        </Container>
    );
}

export default Login;
