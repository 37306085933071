import React, {useEffect, useState} from 'react';
import {Link, redirect} from 'react-router-dom'; // useHistory import 추가
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function FindPw() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        //이메일 전송 요청
        const response = await fetch('/api/auth/findPw', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, email }),
        });

    };


    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                비밀번호 찾기
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    label="이메일"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="유저네임"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                >
                    비밀번호 초기화 및 임시 비밀번호 이메일로 전송
                </Button>

            </Box>
        </Container>
    );
}

export default FindPw;

