import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function SignupConfirm() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const confirmCode = queryParams.get('confirmCode');
    const username = queryParams.get('username');

    const signupConfirm = async () => {

        const response = await fetch('/api/auth/signupConfirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ confirmCode, username })
        });


        if (response.ok) {
            alert("회원가입에 성공하였습니다. 로그인 페이지로 넘어갑니다");
            window.location.href = "/auth/login";
        } else {
            response.json().then((result) => alert("유효하지 않은 요청입니다. 재요청해주세요\n원인: " + result.message));
            window.location.href = "/auth/signup";
            console.error('회원가입 실패');
        }
    }

    useEffect(() => {
        signupConfirm();
    }, []);

    return null; // 렌더링할 내용이 없으므로 null 반환
}

export default SignupConfirm;
