import {Box, Button, Container, Divider, Grid, List, ListItem, ListItemText, Paper, Typography} from "@mui/material";
import {Link, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth} from "../contexts/AuthContext";
import Pagination from "./Pagenation";
import {standardBlackStyle, standardGrayStyle, standardStyle} from "../design/StandardObject";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FlashOnIcon from "@mui/icons-material/FlashOn";
import PublicIcon from "@mui/icons-material/Public";
const Community = (props) => {
    // const [data, setData] = useState([]);
    const { communityName = "freeBoard" } = useParams()

    const [data, setData] = useState({ threadList: [] }); // 초기값으로 threadList를 빈 배열로 설정

    const [totalPage,setTotalPage] = useState({totalPage: 0});

    //페이지 키면 무조건 1부터 시작하도록
    const [currentPage,setCurrentPage] = useState(1);
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const pageValue = queryParams.get('page');   // '1'

    const isRecommended = props.isRecommended;

    const handlePageChange = (page) => {
        // 페이지 변경 이벤트 처리 로직을 여기에 작성
        setCurrentPage(page); // 현재 페이지 업데이트
        // 다른 로직 수행 가능
    };

    const formatTime = (dateString) => {
        const threadDate = new Date(dateString);
        const hours = threadDate.getHours().toString().padStart(2, '0');
        const minutes = threadDate.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const formatDate = (dateString) => {
        const currentDate = new Date();
        const threadDate = new Date(dateString);

        if (currentDate.getFullYear() === threadDate.getFullYear()) {

            if(currentDate.getMonth() === threadDate.getMonth() &&
                currentDate.getDate() === threadDate.getDate()){
                // 현재 날짜와 동일한 경우, 시간과 분만 출력
                return formatTime(dateString);
            }
            const month = (threadDate.getMonth() + 1).toString().padStart(2, '0');
            const day = threadDate.getDate().toString().padStart(2, '0');
            return `${month}.${day}`;

        } else {
            // 다른 경우, 연도, 월, 일 출력
            const year = threadDate.getFullYear();
            const month = (threadDate.getMonth() + 1).toString().padStart(2, '0');
            const day = threadDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    };

    useEffect(() => {
        fetch(`/api/community/${communityName}?page=${currentPage}${isRecommended ? "&isRecommended=1" : ""}`, {
            method: 'GET', // GET 요청인 경우
            headers: {
                'Accept': 'application/json'
                // 필요한 경우에 추가적인 헤더 설정 가능
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data);
                setTotalPage(data.totalPage);
            });
    }, [currentPage,isRecommended]);

    return (
            <Container maxWidth="xl"
                       sx={{
                           mt : 2,
                           backgroundColor: "#F5F5F5",
                           borderRadius: 4,
                           width : "100%",
                           padding : 2,
                           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                           alignItems: 'center'
                       }}
                       component={Paper}
            >

                       {/*sx={standardStyle}>*/}
                <Typography variant="h4" sx={standardGrayStyle}>
                    {communityName === "freeBoard" ? "자유" : communityName} 게시판
                </Typography>

                <List sx={{ backgroundColor:"white",borderRadius:4,padding:2,color:"black" }}>
                    {data?.threadList?.length != 0 ? (
                        data.threadList.map((thread, index) => (
                            <React.Fragment key={index}>
                                <ListItem disablePadding>
                                    {thread.recommended && <FlashOnIcon fontSize="large" />}
                                    <ListItemText
                                        primary={
                                            <Link
                                                to={`/community/${communityName}/${thread.id}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <Typography variant="h6">{thread.title}</Typography>
                                            </Link>
                                        }
                                        secondary={`${thread.username}`}
                                        to={""}
                                    />
                                    <ListItemText sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <FavoriteIcon fontSize="small" sx={{ marginRight: '4px' }} />
                                        <Typography variant="h7" sx={{ marginRight: '8px' }}>{thread.likeCount}</Typography>
                                        <GroupsIcon fontSize="small" sx={{ marginRight: '4px' }} />
                                        <Typography variant="h7" sx={{ marginRight: '8px' }}>{thread.visitCount}</Typography>
                                        <CalendarTodayIcon fontSize="small" sx={{ marginLeft: '4px' }} />
                                        <Typography variant="h7">
                                            {formatDate(thread.modifiedDate !== thread.createdDate ? thread.modifiedDate : thread.createdDate)}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))
                    ) : (
                        data?.threadList?.length === 0 ? (
                            <Typography variant="h7" sx={{ marginRight: '8px' ,
                                textAlign: 'center',
                            }}>게시물이 없습니다</Typography>
                        ) : (
                            <Typography variant="h7" sx={{ marginRight: '8px',
                                textAlign: 'center',
                            }}>개설되지 않은 커뮤니티입니다</Typography>
                        )
                    )}

                </List>


                <Box display="flex" justifyContent="left" sx={{marginTop:2,color:"white"}}>
                    <Button
                        component={Link}
                        to={`/community/${communityName}`}
                        variant="outlined"
                        sx={{ marginX: 1, marginY: 1,backgroundColor:"#212529",color:"white"}}
                    >
                        전체글
                    </Button>

                    <Button
                        component={Link}
                        to={`/community/${communityName}/recommend`}
                        variant="outlined"
                        sx={{ marginX: 1, marginY: 1,backgroundColor:"#212529",color:"white"}}
                    >
                        개념글
                    </Button>



                </Box>

                <Box display="flex" justifyContent="right" sx={{color:"white"}}>
                     <Button
                        component={Link}
                        to={`/community/${communityName}/add`}
                        variant="outlined"
                        color="primary"
                        sx={{ marginX: 1, marginY: 1,backgroundColor:"#212529",color:"white"}}
                    >
                        글쓰기
                    </Button>
                </Box>


                <Box display="flex" justifyContent="center" sx={{color:"white"}}>
                    <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
                </Box>

        </Container>
    );
}

export default Community;




