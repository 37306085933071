import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Box, Button, Container, Paper, Typography} from "@mui/material";
import {useAuth} from "../contexts/AuthContext";
import Logout from "../auth/Logout";
import RankPage from "../rank/RankPage";
import {Grid3x3} from "@mui/icons-material";
import Grid from "@mui/material/Grid/Grid";



function ResultPage() {
    const location = useLocation();
    const msg = location.state.msg;

    return (
        <Container maxWidth="xl">
            <Box align="center">
                <Typography h4>
                    {msg}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    <Button component={Link} to="/" variant="outlined" color="info" sx={{ marginLeft: 2 }}>
                        홈으로 이동
                    </Button>

                </Typography>
            </Box>

        </Container>
    );
}

export default ResultPage;
