import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box,
    Button, Container,
} from "@mui/material";
import authenticatedFetch from "../contexts/AuthenticatedFetch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {ExpandLess, ExpandMore, Image, Notes} from "@mui/icons-material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingUp";
import NotesIcon from '@mui/icons-material/Notes';
import {standardTableStyle} from "../design/StandardObject";


const RecentIncreaseCommunity = () => {
    const [statisticList,setStatisticList] = useState([]);
    const [viewList,setViewList] = useState([]);

    useEffect(() => {
        fetch(`/api/statistic/communityStatistic`)
            .then((response) => response.json())
            .then((data) => {
                setStatisticList(data);
                if(data && data.length > 0){
                    setViewList(data?.slice(0,3));
                }
            });
    }, []); // 빈 배열을 전달하여 최초 한 번만 실행되도록 설정

    const getImageFileName = (id) => {
        if (id % 4 === 0) {
            return "A.jpg";
        } else if (id % 4 === 1) {
            return "B.jpg";
        } else if (id % 4 === 2) {
            return "C.jpg";
        } else {
            return "D.jpg";
        }
    };

    const viewMore = () => {
        if (viewList.length === 3) {
            setViewList(statisticList.slice(0, 10));
        } else{
            setViewList(statisticList.slice(0, 3));
        }
        // setShowMoreButton((prevShowMoreButton) => !prevShowMoreButton); // 버튼 상태 변경
    };

    const openCommunityURL = (idolId,url) => {
        window.location.href="/community/" + url;
    }

    return (
        <Container maxWidth="xl"
                   sx={{
                       width:"100%",
                       maxWidth:"xl",
                       borderRadius: 4,
                       marginTop: 2,
                       padding: 2,
                       display: 'flex',
                       flexDirection: 'column',
                       alignItems: 'center',
                       backgroundColor: "#F5F5F5",
                   }}
        >

            <TableContainer
                sx={{
                    backgroundColor: "#F5F5F5",
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: 2,
                        borderRadius: 4,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        backgroundColor:"white"
                    }}
                >

                    <Typography variant="h6" sx={{ flex: 1, marginLeft: 2,textAlign : "center"}}>
                        최근 관심이 쏠리는 채널
                    </Typography>
                </Box>

                <TableContainer component={Paper} sx={{ borderRadius: 4, width: '100%', marginTop: 2 ,minHeight:"250px"}}>
                    <Table sx={standardTableStyle}>
                        <TableHead sx={{ height: '1px' }}>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">채널명</TableCell>
                                {/*<TableCell align="center">상승세<TrendingUpIcon sx={{ fontSize: '20px' }} /></TableCell>*/}
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{minHeight: "250px"}}>
                            {viewList.length > 0 &&
                                viewList.map((statistic) => (
                                    <TableRow
                                        key={statistic.id}
                                        onClick={() =>
                                            openCommunityURL(
                                                statistic.id,
                                                statistic.communityURL
                                            )
                                        }
                                        sx={{
                                            cursor: "pointer",
                                            height: "50px", // 행의 높이를 50px로 고정
                                        }}
                                    >
                                        <TableCell align="center">
                                            <Box>
                                                <img
                                                    src={`${statistic.communityIdolThumbnail}`}
                                                    style={{ width: "50px", height: "50px" }}
                                                />
                                            </Box>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                {statistic.communityName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography
                                                style={{
                                                    fontSize: "1.1rem",
                                                }}
                                            >
                                                {statistic.diffPercentage >= 100 ? "🔥🔥" : "🔥"}
                                                {/*{statistic.diffPercentage + "% "}*/}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableContainer>

            <div>
                {statisticList.length > 3 && (
                    <div>
                        {viewList.length > 3 ? (
                            <ExpandLess
                                onClick={viewMore}
                                sx={{ color: "black" }}
                            />
                        ) : (
                            <ExpandMore
                                onClick={viewMore}
                                sx={{ color: "black" }}
                            />
                        )}
                    </div>
                )}
            </div>

        </Container>
    );

};

export default RecentIncreaseCommunity;
