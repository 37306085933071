import { Box, Button, Container, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import authenticatedFetch, {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import {useAuth} from "../contexts/AuthContext";
import async from "async";

const Admin = () => {
    const {communityURL} = useParams(), [data, setData] = useState(), authenticatedFetch = useAuthenticatedFetch(),
        handleInitButtonClick = async () => {
            const url = "/api/admin/init";
            // "/api/admin/init"로 POST 요청 보내는 로직을 여기에 추가
            const response = await authenticatedFetch(url, {
                method: "POST",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);
            }
        }, handleSetInitRankButtonClick = async () => {
            const url = "/api/idol/setInitRanking";
            const response = await authenticatedFetch(url, {
                method: "GET",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);
            }

        }, handlePopularCommunityAdd = async () => {
            const url = "/api/admin/initPopularCommunity";
            const response = await authenticatedFetch(url, {
                method: "POST",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);
            }
        }, handleMakeInitFile = async () => {
            const url = "/api/admin/initRankingFile";
            const response = await authenticatedFetch(url, {
                method: "POST",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);

            }

        }, handleSearchAndSetRankButtonClick = async () => {
            const url = "/api/idol/rankSearch";

            const response = await authenticatedFetch("/api/idol/rankSearch", {
                method: "POST",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);

            }

        }, handleCalRecentFamous = async () => {
            const url = "/api/admin/calRecentFamous";
            const response = await authenticatedFetch(url, {
                method: "PUT",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);

            }

        }, handleRecentVideo = async () => {
            const url = "/api/admin/handleRecentVideo";
            const response = await authenticatedFetch(url, {
                method: "GET",
            });

            if (response.ok) {
                alert("요청 수행 완료 ");
            } else {
                console.log(response);

            }

        };


    return (
        <Container maxWidth="xl" sx={{ paddingY: 4, textAlign: 'center' }}>
            <Button variant="outlined" color="primary" onClick={handleInitButtonClick}>
                유저,커뮤니티,쓰레드의 더미 데이터 생성
            </Button>

            <Button variant="outlined" color="primary" onClick={handleSetInitRankButtonClick}>
                파일기반 aiddoru 초기화 및 갱신
            </Button>

            <Button variant="outlined" color="primary" onClick={handleSearchAndSetRankButtonClick}>
                검색기반 aiddoru 초기화 및 갱신
            </Button>

            <Button variant="outlined" color="primary" onClick={handleMakeInitFile}>
                위키 HTML 기반 파일 생성
            </Button>

            <Button onClick={handlePopularCommunityAdd }>
                popular 10개 커뮤니티 생성
            </Button>

            <Button onClick={handleCalRecentFamous }>
                aiddoru 상승세 계산
            </Button>

            <Button component={Link} to="addcommunity" variant="outlined" color="primary">
                커뮤니티 추가
            </Button>

            <Button component={Link} to="userManagement" variant="outlined" color="primary">
                유저 차단
            </Button>

            <Button component={Link} to="modifyIdol" variant="outlined" color="primary">
                아이돌 관리
            </Button>

            <Button onClick={handleRecentVideo } >
                handleRecentVideo
            </Button>



        </Container>
    );
}

export default Admin;
