import styled from 'styled-components';

export const StyledBtn = styled.button`
    margin-left: 1rem;
    margin-top: 1rem;
    background-color: #007BFF;
    color: white;
`;


