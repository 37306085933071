import {styled} from "@mui/system";
import {Button} from "@mui/material";

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '20px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#0056b3',
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
    },
    '&:active': {
        backgroundColor: '#003f7f',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.4)',
    },
    '&:disabled': {
        backgroundColor: '#CCCCCC',
        color: '#666666',
        boxShadow: 'none',
    },
}));

export default CustomButton;