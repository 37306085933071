import React from 'react';
import {Route, Navigate, Outlet} from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import admin from "./admin/Admin"; // useAuth를 import

// 커스텀 PrivateRoute 컴포넌트
function PrivateRoute({ element, adminOnly, ...props }) {
    const { isLoggedIn, role } = useAuth();

    // 로그인하지 않은 경우 로그인 페이지로 리다이렉트
    if (!isLoggedIn) {
        return <Navigate to="/auth/login" />;
    }


    // 어드민 페이지에 대한 접근 제어
    if (adminOnly && !isAdminValidRole()) {
        return <Navigate to="/" />;
    }


    function isAdminValidRole(){
        return isLoggedIn && role?.includes("ROLE_ADMIN");
    }

    function isUserValidRole(){
        return isLoggedIn && (role?.includes("ROLE_USER") || role.includes("ROLE_ADMIN"));
    }

    return <Outlet />;
    // return <Route {...props} element={element} />;

}



export default PrivateRoute;
