import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box,
    Button, Container, formControlClasses,
} from "@mui/material";
import authenticatedFetch from "../contexts/AuthenticatedFetch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingUp';
import NotesIcon from "@mui/icons-material/Notes";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupsIcon from "@mui/icons-material/Groups";
import PublicIcon from "@mui/icons-material/Public";
import {standardTableStyle} from "../design/StandardObject";
import CustomButton from "../design/CustomButton";
import CountrySelector from "../components/CountrySelector";

const RecentIncreaseAiddoru = () => {
    const [statisticList, setStatisticList] = useState([]);
    const [viewList, setViewList] = useState([]);
    const [showMoreButton, setShowMoreButton] = useState(true);
    const [country,setCountry] = useState(null);

    useEffect(() => {

    }, [showMoreButton]);

    useEffect(() => {
        fetch(`/api/idol/recentHotIdol?country=${country === null ? 'KR' : country}`)
            .then((response) => response.json())
            .then((data) => {
                setStatisticList(data);
                if (data.length > 0) {
                    console.log(data);
                    setViewList(data.slice(0, 3));
                    if (data.length <= 3) {
                        setShowMoreButton(false);
                    }else{
                        setShowMoreButton(true);
                    }
                }else{
                    setViewList([]);
                }
            });
    }, [country]);

    const viewMore = () => {
        if (viewList.length === 3) {
            setViewList(statisticList.slice(0, 10));
        } else{
            setViewList(statisticList.slice(0, 3));
        }
        // setShowMoreButton((prevShowMoreButton) => !prevShowMoreButton); // 버튼 상태 변경
    };


    // const openChannelURL = (idolId,url) => {
    const openChannelURL = (idolId,communityName) => {
        fetch(`/api/idol/updateClick?idolId=${idolId}`,{
            method: 'POST',
        });

        window.open("about:blank").location.href="/community/"+communityName;
    }

    const getImageFileName = (id) => {
        if (id % 4 === 0) {
            return "A.jpg";
        } else if (id % 4 === 1) {
            return "B.jpg";
        } else if (id % 4 === 2) {
            return "C.jpg";
        } else {
            return "D.jpg";
        }
    };

    const countries = [
        { code: 'KR', label: 'South Korea' },
        { code: 'US', label: 'United States' },
        { code: 'JP', label: 'Japan' },
    ];


    return (
        <Container maxWidth="xl"
                   sx={{
                       width:"100%",
                       maxWidth:"xl",
                       display: 'flex',
                       flexDirection: 'column',
                       alignItems: 'center',
                       backgroundColor: "#F5F5F5",
                   }}
        >

            <TableContainer
                sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: 4,
                }}
            >
                <Box
                    sx={{
                        mt :2,
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // 화면 크기에 따라 flexDirection 조정
                        alignItems: 'center',
                        justifyContent: { xs: 'center', sm: 'space-between' }, // 작은 화면에서는 중앙 정렬, 큰 화면에서는 공간 분배
                        width: '100%',
                        padding: { xs: 1, sm: 2,md:2,xl:2 }, // 화면 크기에 따라 패딩 조정
                        borderRadius: 4,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'white',
                        gap: { xs: 1, sm: 2 }, // 요소들 사이의 간격 조정
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            flexGrow: 1, // 남은 공간을 차지하도록 설정
                            mb: { xs: 1, sm: 0 }, // 화면 크기에 따라 하단 여백 조정

                        }}
                    >
                        <Typography variant="h6">
                            주목 상승세
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: { xs: 'center', sm: 'flex-end' }, // 작은 화면에서는 중앙 정렬, 큰 화면에서는 오른쪽 정렬
                            width: 'auto', // 필요한 너비에 맞게 조정
                        }}
                    >
                        <CountrySelector countries={countries} setCountry={setCountry} />
                    </Box>
                </Box>


                <div style={{minHeight:"250px",marginTop:"16px",
                }} sx={{
                }}>

                    <Table sx={standardTableStyle}>
                        {viewList.length !== 0 && (
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">썸네일</TableCell>
                                    <TableCell align="center">채널명</TableCell>
                                    <TableCell align="center">구독자수</TableCell>
                                    <TableCell align="center">상승세 <TrendingUpIcon sx={{ fontSize: '20px' }}/></TableCell>
                                    <TableCell>최근 영상</TableCell>
                                </TableRow>
                            </TableHead>
                        )}

                        <TableBody>
                            {viewList.length > 0 ? (
                                viewList.map((recentHotIdol) => (
                                    <TableRow
                                        key={recentHotIdol.id}
                                        onClick={() =>
                                            openChannelURL(recentHotIdol.id, recentHotIdol.channelName.split(" ")[0])
                                        }
                                        sx={{ cursor: "pointer", height: "50px" }}
                                    >
                                        <TableCell align="center">
                                            <Box>
                                                <img
                                                    src={`${recentHotIdol.channelThumbnailURL}`}
                                                    style={{ width: "50px", height: "50px" }}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    fontSize: "1.2rem",
                                                }}
                                                onClick={() => {
                                                    window.open("about:blank").location.href=`${recentHotIdol.channelUrl}`;
                                                }}
                                            >
                                                {recentHotIdol.channelName}
                                            </Typography
                                            >
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                {recentHotIdol.subscriberCount.toLocaleString('en-US') }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                {recentHotIdol.idolToday.subscribeDiffPercentage + "% "}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {recentHotIdol.idolVideoInfoList?.length > 0 &&
                                                recentHotIdol.idolVideoInfoList.slice(0,3).map((idolVideoInfo) => (
                                                    <img
                                                        src={idolVideoInfo.videoThumbnailURL}
                                                        style={{ width: "50px", height: "50px" }}
                                                    />
                                                ))}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <Typography sx={{ minHeight: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>상승세 통계가 준비중입니다.</Typography>
                            )}
                        </TableBody>
                    </Table>

                </div>
            </TableContainer>

            <Button>    
                {statisticList.length > 3 && showMoreButton && (
                    <div>
                        {viewList.length > 3 ? (
                            <ExpandLess onClick={viewMore} sx={{ color: "black" }} />
                        ) : (
                            <ExpandMore onClick={viewMore} sx={{ color: "black" }} />
                        )}
                    </div>
                )}
            </Button>
        </Container>
    );

};

export default RecentIncreaseAiddoru;
