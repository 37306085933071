import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Main from './components/Main';
import CommunityList from "./community/CommunityList";
import Community from "./community/Community";
import Thread from "./community/Thread";
import Admin from "./admin/Admin";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './PrivateRoute';
import ThreadAdd from "./community/ThreadAdd";
import ThreadModify from "./community/ThreadModify";
import AuthenticatedFetchContext, { AuthenticatedFetchProvider } from "./contexts/AuthenticatedFetch";
import {Box, Container, createTheme, CssBaseline, GlobalStyles, Grid, Paper, ThemeProvider} from "@mui/material";
import Header from "./components/Header";
import RecentIncreaseAiddoru from "./statistic/RecentIncreaseAiddoru";
import Footer from "./components/Footer";
import RecentIncreaseCommunity from "./statistic/RecentIncreaseCommunity";
import AiddoruStatistic from "./statistic/AiddoruStatistic";
import {maxHeight} from "@mui/system";
import StatisticArea from "./statistic/StatisticArea";
import AddCommunity from "./admin/AddCommunity";
import ModifyIdol from "./admin/ModifyIdol";
import UserManagement from "./admin/UserManagement";
import PopularThread from "./statistic/PopularThread";
import RecommendAiddoru from "./statistic/RecommendAiddoru";
import ResultRecommendAiddoru from "./statistic/ResultRecommendAiddoru";
import SignupConfirm from "./auth/SignupConfirm";
import FindId from "./auth/FindId";
import FindPw from "./auth/FindPw";
import ResultPage from "./components/ResultPage";
import ChangeUserInfo from "./auth/ChangeUserInfo";

import StatisticButton from "./statistic/StatisticButton";
import CheckAuthenticate from "./auth/CheckAuthenticated";
import UserProfile from "./auth/UserProfile";
import MyInfo from "./auth/MyInfo";


function App() {
    const [accessToken] = useState(localStorage.getItem('accessToken') !== "");

    const theme = createTheme({
        palette: {
            background: {
                default: "#ffffff",
            },
            text: {
                primary: "#212529",
            },
        },
    });



    return (

        <Box
            sx={{
                minWidth: 1024,
                overflowX: 'auto',
            }}
        >
            <AuthenticatedFetchProvider accessToken={accessToken}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <AuthProvider>

                            <Header>
                            </Header>

                            {
                                accessToken &&
                                <UserProfile />
                            }

                            <StatisticArea sx={{"backgroundColor" : "black"}}/>
                            <Routes>
                                <Route path="/" element={<Main />} />
                                {/* 다른 경로에 대한 라우트 설정 */}
                                <Route path="/community" element={<CommunityList />} />
                                <Route path="/community/:communityName" element={<Community />} />
                                <Route path="/community/:communityName/:threadId" element={<Thread />} />

                                {/*로그인이상의 권한이 필요한 동작*/}
                                <Route element={<PrivateRoute  />}>
                                    <Route path="/community/:communityName/add" element={<ThreadAdd />} />
                                    <Route path="/community/:communityName/:threadId/modify" element={<ThreadModify />} />
                                    <Route path="/community/:communityName/recommend" element={<Community isRecommended={true} />} />
                                    <Route path="/recommendAiddoru" element={<RecommendAiddoru />} />
                                    <Route path="/resultRecommendAiddoru" element={<ResultRecommendAiddoru />} />

                                    <Route path="/auth/myInfo" element={<MyInfo />} />
                                    <Route path="/auth/changeUserInfo" element={<ChangeUserInfo />} />
                                </Route>

                                {/*어드민이상의 권한이 필요한 동작*/}
                                <Route element={<PrivateRoute adminOnly={true} />}>
                                    <Route path="/admin" element={<Admin />} adminOnly={true} />
                                    <Route path="/admin/addcommunity" element={<AddCommunity />} adminOnly={true} />
                                    <Route path="/admin/modifyidol" element={<ModifyIdol />} adminOnly={true} />
                                    <Route path="/admin/userManagement" element={<UserManagement />} adminOnly={true} />
                                </Route>


                                <Route path="/auth/login" element={<Login />} />
                                <Route path="/auth/signup" element={<Signup />} />
                                <Route path="/auth/signupConfirm" element={<SignupConfirm />} />

                                <Route path="/auth/findId" element={<FindId />} />
                                <Route path="/auth/findPw" element={<FindPw />} />
                                <Route path="/resultPage" element={<ResultPage />} />
                                <Route path="/auth/changeUserInfo" element={<ChangeUserInfo />} />
                                {/*<Route path="/auth/findId" element={<ChangeUserInfo />} />*/}

                            </Routes>

                            <Footer>

                            </Footer>


                        </AuthProvider>
                    </Router>
                </ThemeProvider>
            </AuthenticatedFetchProvider>
        </Box>

    );
}

export default App;
