import React from 'react';
import {AppBar, Box} from "@mui/material";

export const standardBackground = {background : '#212529'};
export const textColor = {text : '#ffffff'};


function ColorStandard({ children, ...restProps })  {
    return (
        <Box position="static" sx={{ background: '#212529' }} {...restProps}>
            {children}
        </Box>
    );
}
export default ColorStandard;
