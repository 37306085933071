import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    MenuItem,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid/Grid';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Box from '@mui/material/Box/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { standardGrayStyle } from '../design/StandardObject';

function ModifyIdol() {
    const location = useLocation();
    const navigate = useNavigate();
    const [idolList, setIdolList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const queryParams = new URLSearchParams(location.search);
    const [viewAll, setViewAll] = useState(true);

    // 아이돌 목록을 관리하는 상태
    const [checkedItems, setCheckedItems] = useState({});
    const [channelMap, setChannelMap] = useState({});
    const [selectedCountry, setSelectedCountry] = useState('');

    // 체크된 아이돌을 업데이트하는 함수
    const handleCheckChange = (id) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [id]: !prevCheckedItems[id], // 토글 기능
        }));
    };

    // 삭제 버튼을 클릭하면 호출되는 함수
    const handleDeleteClick = () => {
        // 체크된 아이돌이 있는지 확인
        const selectedIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);

        if (selectedIds.length === 0) {
            // 체크된 아이돌이 없으면 삭제하지 않고 알림을 표시
            alert('삭제할 아이돌을 선택하세요.');
        } else {
            // 체크된 아이돌이 있으면 삭제 확인 메시지 표시
            const confirmDelete = window.confirm('선택한 아이돌을 삭제하시겠습니까?');

            if (confirmDelete) {
                // checkedItems 맵을 배열로 변환
                const data = Object.keys(checkedItems).filter((id) => checkedItems[id]);


                fetch('/api/admin/delete', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }).then(r => console.log("delete 완") );
            // }).then(r => window.location.href = "" )

            }
        }
    };

    const handleModifyIdol = () => {
        const data = Object.keys(channelMap).map((id) => ({
            id,
            channelName: channelMap[id].channelName,
            country: selectedCountry,
        }));

        // 서버로 데이터를 전송하거나 다른 작업을 수행할 코드를 추가
        // 예: fetch()를 사용하여 서버로 데이터 전송

        // 예: 데이터를 서버로 전송하고 응답을 처리하는 코드
        fetch('/api/admin/modify', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    console.log('아이돌 정보가 업데이트되었습니다.');
                } else {
                    console.error('아이돌 정보 업데이트 실패');
                }
            })
            .catch((error) => {
                console.error('아이돌 정보 업데이트 오류:', error);
            });
    };

    const setIdolRankList = async () => {
        let response;
        if (viewAll) {
            response = await fetch(`/api/idol/rank?page=${currentPage}`);
        } else {
            response = await fetch(`/api/idol/noCountry?page=${currentPage}`);
        }

        if (response.ok) {
            const data = await response.json(); // 응답을 JSON으로 파싱
            setIdolList(data);
        }
    }

    useEffect(() => {
        setIdolRankList();
    }, [currentPage, viewAll]); // 빈 배열을 전달하여 최초 한 번만 실행되도록 설정

    const handleChangeChannelName = (channelName, id) => {
        const updatedChannelMap = { ...channelMap };

        if (!updatedChannelMap[id]) {
            updatedChannelMap[id] = { channelName };
        } else {
            updatedChannelMap[id].channelName = channelName;
        }

        setChannelMap(updatedChannelMap);
    }

    const handleChangeCountry = (country, id, index) => {
        setSelectedCountry(country);
        const updatedChannelMap = { ...channelMap };
        idolList[index].country = country;

        if (!updatedChannelMap[id]) {
            updatedChannelMap[id] = { country };
        } else {
            updatedChannelMap[id].country = country;
        }

        setChannelMap(updatedChannelMap);
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Container
            maxWidth="xl"
            sx={{
                paddingY: 4,
                textAlign: 'center',
                borderRadius: 4,
            }}
        >
            <Typography variant="h2" gutterBottom>
                아이돌 관리
            </Typography>

            <Button onClick={() => setViewAll(true)}>전체 아이돌 관리</Button>
            <Button onClick={() => setViewAll(false)}>국가없는 아이돌 관리</Button>

            <Grid container fullWidth spacing={1} sx={{ backgroundColor: 'grey', borderRadius: 4 }}>
                <Grid md={12}>
                    <Paper elevation={3} sx={{ marginTop: 3, padding: 3, marginBottom: 3, backgroundColor: 'grey', borderRadius: 4 }}>
                        <Typography variant="h4" sx={{ marginBottom: 2, color: '', fontSize: '1rem' }}>
                            <FlashOnIcon fontSize="large"></FlashOnIcon>
                        </Typography>
                    </Paper>
                    {idolList && idolList.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <TableContainer component={Paper} fullWidth sx={standardGrayStyle}>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>삭제여부</TableCell>
                                            <TableCell>썸네일이에요잉</TableCell>
                                            <TableCell>채널명</TableCell>
                                            <TableCell>구독자수</TableCell>
                                            <TableCell>총 조회수</TableCell>
                                            <TableCell format={(value) => value.toLocaleString('en-US')}>업로드한 영상수</TableCell>
                                            <TableCell>국가</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {idolList.length > 0 &&
                                            idolList.map((idol, index) => (
                                                <React.Fragment key={idol.id}>

                                                    <TableRow sx={{ cursor: 'pointer' }}>
                                                        <TableCell align="center">
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedItems[idol.id] || false} // 체크 상태를 체크된 아이템 상태에 따라 설정
                                                                onChange={() => handleCheckChange(idol.id)} // 체크 상태 변경 시 핸들러 호출
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <img src={`${idol.channelThumbnailURL}`} style={{ width: '50px', height: '50px' }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                label="채널명"
                                                                value={channelMap[idol.id] ? channelMap[idol.id].channelName : idol.channelName}
                                                                // value={idol.channelName}
                                                                onChange={(e) => handleChangeChannelName(e.target.value, idol.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{idol.subscriberCount.toLocaleString('en-US')}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{idol.viewCount.toLocaleString('en-US')}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{idol.videoCount}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                select
                                                                label="국가"
                                                                value={idol.country}
                                                                onChange={(e) => handleChangeCountry(e.target.value, idol.id, index)}
                                                                fullWidth // To maximize width
                                                                sx={{ marginBottom: 2 }}
                                                            >
                                                                <MenuItem value="KR">한국 (KR)</MenuItem>
                                                                <MenuItem value="JP">일본 (JP)</MenuItem>
                                                                <MenuItem value="US">미국 (US)</MenuItem>
                                                            </TextField>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                sx={{
                                    marginLeft: 1,
                                    marginTop: 1,
                                    backgroundColor: '#007BFF',
                                    color: 'white',
                                }}
                                onClick={() => handleModifyIdol()}
                            >
                                수정
                            </Button>

                            <Button
                                sx={{
                                    marginLeft: 1,
                                    marginTop: 1,
                                    backgroundColor: '#FF0000', // 삭제 버튼 색상 설정
                                    color: 'white',
                                }}
                                onClick={handleDeleteClick} // 삭제 버튼 클릭 시 처리 함수 호출
                            >
                                삭제
                            </Button>
                        </Box>
                    )}
                    <Button
                        onClick={() => onPageChange(currentPage - 1)}
                        sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: '#007BFF',
                            color: 'white',
                            alignContent: 'center',
                        }}
                    >
                        <NavigateBeforeIcon />
                    </Button>

                    <Button
                        sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: '#007BFF',
                            color: 'white',
                        }}
                    >
                        {currentPage}
                    </Button>

                    <Button
                        onClick={() => onPageChange(currentPage + 1)}
                        sx={{
                            marginLeft: 1,
                            marginTop: 1,
                            backgroundColor: '#007BFF',
                            color: 'white',
                            alignContent: 'center',
                        }}
                    >
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ModifyIdol;
