import React, { useEffect } from 'react';

function DisplayAds() {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const script = document.createElement('script');
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5689252368770626";
            script.async = true;
            script.crossOrigin = "anonymous";

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, 1000); // 1초 (1000ms) 후에 실행

        // 컴포넌트 언마운트 시 타이머를 클리어
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div>
            <ins class="adsbygoogle"
                 style={{ display: "block" }}
                 data-ad-client="ca-pub-5689252368770626"
                 data-ad-slot="5965951272"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </div>
    );
}

export default DisplayAds;