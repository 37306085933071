// src/components/Header.js
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import ColorStandard, {standardBackground} from "../design/StandardColor";
import StandardColor from "../design/StandardColor";
import {StyledImg} from "../design/StandardImg";

function Header() {
    const {
        isLoggedIn,
        setIsLoggedIn,
        role,
        setRole,
        loggedUser,
        setLoggedUser,
        accessToken,
        setAccessToken,
        profileImgSrc,
        localLogout
    } = useAuth();

    const headerBtnStyle = {
        color: 'white',
        fontSize: '1rem',
    };

    return (
        <AppBar position="static" sx={standardBackground}>
            <Toolbar>
                <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                    Aiddoru
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: "#007BFF",
                        color: "white",
                        borderRadius: 2,
                        overflowX: 'auto',
                        flexDirection: 'row', // 기본적으로 가로 방향
                        flexWrap: 'nowrap',   // 버튼이 세로로 쌓이지 않도록 설정
                    }}
                >
                    {isLoggedIn ? (
                        <>
                            <Box>
                                <Button component={Link} onClick={localLogout} sx={headerBtnStyle}>
                                    로그아웃
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box>
                                <Button component={Link} to="/auth/login" sx={headerBtnStyle}>
                                    로그인
                                </Button>
                            </Box>
                            <Box>
                                <Button component={Link} to="/auth/signup" sx={headerBtnStyle}>
                                    회원가입
                                </Button>
                            </Box>
                        </>


                    )}
                    {role?.includes("ROLE_ADMIN") &&
                        <Button component={Link} to="/admin" sx={headerBtnStyle}>
                            어드민 페이지
                        </Button>}

                    <Button
                        component={Link}
                        to="/community"
                        sx={headerBtnStyle}
                    >
                        커뮤니티
                    </Button>

                    <Button
                        component={Link}
                        to="/auth/myInfo"
                        sx={headerBtnStyle}
                    >
                        마이 페이지
                    </Button>
                </Box>


            </Toolbar>

        </AppBar>

    );
}

export default Header;
