import React, {createContext, useContext, useState} from 'react';
import {useAuth} from "./AuthContext";

// Create a new context
const AuthenticatedFetchContext = createContext();

// Create a custom hook to access the context
export function useAuthenticatedFetch() {
    return useContext(AuthenticatedFetchContext);
}

// Export the logout function
export async function logout() {
    const logoutResponse = await fetch("/api/auth/logout",{
        method : "DELETE"
    });
    if (logoutResponse.ok) {
        window.location.href = "/auth/login";
    } else {
        console.log("logout request failed");
    }
}

// Create a provider component that accepts accessToken as a prop
export function AuthenticatedFetchProvider({ children }) {
    const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken")); // Initially, it's null
    const {localLogout} = useAuth();


    const authenticatedFetch = async (url, options = {}) => {
        if (accessToken) {
            if (!options.headers) {
                options.headers = {};
            }
            options.headers.Authorization = `Bearer ${accessToken}`
        }

        const response = await fetch(url, options);

        if (response.status === 401 || response.status === 403) {
            if (response.status === 403) {
                alert("해당 요청을 수행할 권한이 없습니다");
                return;
            }

            const refreshTokenResponse = await fetch("/api/auth/refreshToken", {
                method: "POST",
            });

            console.log(refreshTokenResponse);

            if (refreshTokenResponse.ok) {
                const newAccessToken = await refreshTokenResponse.text();
                setAccessToken(newAccessToken);
                options.headers.Authorization = `Bearer ${newAccessToken}`;
                return await fetch(url, options);
            } else {
                setAccessToken(null);
                localLogout();
                alert("로그인 토큰이 만료되었습니다. 재로그인 해주십시오");
                await logout(); // 여기서 logout 함수를 호출합니다.
            }
        }

        return response;
    };

    return (
        <AuthenticatedFetchContext.Provider value={authenticatedFetch}>
            {children}
        </AuthenticatedFetchContext.Provider>
    );
}

export default AuthenticatedFetchContext;
