import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment, InputLabel,
    List,
    ListItem,
    ListItemText, Paper,
    TextField,
    Typography
} from "@mui/material";
// 추가: 아이콘들을 import
import SendIcon from "@mui/icons-material/Send";
import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {useAuthenticatedFetch} from "../contexts/AuthenticatedFetch";
import {useAuth} from "../contexts/AuthContext";
import {Close, DoneRounded, SubdirectoryArrowRightOutlined} from "@mui/icons-material";
import Grid from "@mui/material/Grid/Grid";
import CommentItem from "./CommentItem";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import DoneIcon from '@mui/icons-material/Done';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AddCommentIcon from '@mui/icons-material/AddComment';
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
const Comment = ({commentList}) => {
    const [comment, setComment] = useState("");
    const [modifyContent, setModifyContent] = useState("");
    const {loggedUser, setLoggedUser} = useAuth();
    const {communityName, threadId} = useParams();
    const authenticatedFetch = useAuthenticatedFetch();
    const [isEditing, setIsEditing] = useState(false);
    const [editingCommentId, setEditingCommentId] = useState(null); // Track the comment being edited
    const [modifyId, setModifyId] = useState(0);
    const [prevHighlightElement, setPrevHighlightElement] = useState(null);

    const navigate = useNavigate();


    //념댓글 클릭시 이동
    const scrollToComment = (index) => {
        if (index >= 0 && index <= commentList.length) {
            const element = document.getElementById(index);
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
                element.parentElement.parentElement.parentElement.style.backgroundColor = "";
                element.parentElement.parentElement.parentElement.style.borderRadius = "4px";
                if (prevHighlightElement) {
                    prevHighlightElement.style.backgroundColor = "transparent";
                }
                setPrevHighlightElement(element);
            }

        }
    };

    //념댓글 계산
    const getTopLikedComments = (comments) => {
        if (!comments) return;
        const result = comments
            .filter((comment) => comment.positiveHeartCount > 0)
            .sort((a, b) => b.positiveHeartCount - a.positiveHeartCount)
            .slice(0, 3); // Get the top 3 liked comments
        return result;
    };

    //념댓글
    const topLikedComments = getTopLikedComments(commentList);

    //댓글 수정
    const handleCommentModifySubmit = async () => {
        try {
            const response = await authenticatedFetch(`/api/comment/${communityName}/${threadId}/${modifyId}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({content: modifyContent}),
            });

            if (response.ok) {
                window.location.reload();
            } else {
                alert("댓글 작성에 실패하였습니다");
            }
        } catch (error) {
            console.log(error);
        }
    };


    //댓글 제출
    const handleCommentAddSubmit = async () => {
        try {
            const response = await authenticatedFetch(`/api/comment/${communityName}/${threadId}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({content: comment}),
            });

            if (response.ok) {
                window.location.reload();
            } else {
                alert("댓글 작성에 실패하였습니다");
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleEditClick = (commentId) => {
        //팝업
        if (isEditing) {
            setIsEditing(false);
            setEditingCommentId(0); // Store the comment being edited
            setModifyId(0);

        } else {
            setIsEditing(true);
            setEditingCommentId(commentId); // Store the comment being edited
            setModifyId(commentId);
            const editedComment = commentList.find((comment) => comment.id === commentId);
            if (editedComment) {
                setModifyContent(editedComment.content);
            }
        }
    };

    return (
        <div>
            <List>

                <Paper elevation={3} sx={{ marginTop: 3, padding: 2, marginBottom: 2, color:"black",backgroundColor: 'white', borderRadius: 4 }}>
                    {topLikedComments?.length > 0 || commentList?.length > 0 ? (
                        <Typography variant="h4" sx={{ marginBottom: 3 }}>댓글</Typography>
                    ) : (
                        <Typography variant="h4" sx={{ marginBottom: 3 }}>지금은 댓글이 없어요..</Typography>
                    )}                    {topLikedComments?.map((commentItem, index) => (
                        <Grid container spacing={1} alignItems="center" maxWidth="xl" sx={{
                            backgroundColor : "white",
                            borderRadius: 4,
                            marginTop : 2
                        }}>
                            <ListItem maxWidth="lg"
                                onClick={() => scrollToComment(commentItem.id)}
                                style={{cursor: "pointer",}}
                            >
                                <Grid item lg={11} >
                                    <CommentItem
                                        commentItem={commentItem}
                                        onEditClick={handleEditClick}
                                        isSubComment={false}
                                        isRecommend={true}
                                    />
                                </Grid>
                                <Grid item lg={1}>
                                    <ThumbUpIcon sx={{color: '#212529',textAlign: 'right' }} ></ThumbUpIcon>
                                </Grid>
                            </ListItem>


                        </Grid>
                    ))}

                </Paper>

                {commentList &&
                    commentList.map((commentItem) => (

                        <>
                            <Paper>

                                {isEditing && editingCommentId === commentItem.id ? (
                                    <Grid container spacing={1} alignItems="center" maxWidth="lg">
                                        <Grid item lg={11}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={modifyContent}
                                                onChange={(e) => setModifyContent(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item lg={1}>
                                            <IconButton color="primary" onClick={handleCommentModifySubmit} sx={{ color: "black" }}>
                                                <DoneRoundedIcon />
                                            </IconButton>
                                            <IconButton variant="outlined" color="secondary" onClick={handleEditClick} sx={{ color: "black" }}>
                                                <Close />
                                            </IconButton>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <InputLabel>{`작성자: ${commentItem.username}`}</InputLabel>
                                        </Grid>
                                    </Grid>
                                ) : (

                                    <Box sx={{padding : 2}}>
                                        {/*댓글 출력부분*/}
                                        <Grid container spacing={1} alignItems="center" maxWidth="xl" sx={{
                                            backgroundColor : "white",
                                            borderRadius: 4,
                                        }}>
                                            {!commentItem.isSubComment &&
                                                <ListItem key={commentItem.id} >
                                                    {/*subComment가 아니라면*/}
                                                    <Grid item xs={12}>
                                                        <CommentItem
                                                            commentItem={commentItem}
                                                            ownerCommentId={commentItem.id}
                                                            onEditClick={handleEditClick}
                                                        />
                                                    </Grid>
                                                </ListItem>
                                            }

                                            {/*자신의 대댓글이 존재하면 */}
                                            {!(commentItem.isSubComment) && commentItem.subComment?.map((subComment) => (
                                                <ListItem key={subComment.id}>
                                                    <Grid item xs={1} maxWidth="xl">
                                                    </Grid>

                                                    <Grid item xs={11} maxWidth="xl">
                                                        <CommentItem commentItem={subComment}
                                                                     onEditClick={handleEditClick}
                                                                     ownerCommentId={commentItem.id}
                                                                     isSubComment={true}/>
                                                    </Grid>
                                                </ListItem>
                                            ))
                                            }


                                        </Grid>
                                    </Box>


                                )}
                            </Paper>
                            {/* 댓글 수정 입력란*/}
                        </>

                    ))}
            </List>

            <Box sx={{ padding: 2 }}>
                {/* 댓글 출력 부분 */}
                <Grid container spacing={1} alignItems="center" maxWidth="xl">
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            value={(loggedUser != null && loggedUser != "null" && loggedUser.length != 0 ) ? loggedUser  : "로그인이 필요합니다"}
                            variant="outlined"
                            readOnly
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={9}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={10} sm={11} md={11}>
                                <TextField
                                    label="댓글 작성"
                                    variant="outlined"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2} sm={1} md={1}>
                                <IconButton onClick={handleCommentAddSubmit}>
                                    <DoneRoundedIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>




        </div>
    );
};

export default Comment;
